import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../../services/resource.service';
import { ProductService } from '../../../services/product.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { HelpersService } from '../../../services/helpers.service';
import { SelectionService } from '../../../services/selection.service';
import moment from 'moment';

@Component({
    selector: 'app-selection-detail-modal',
    templateUrl: './selection-detail-modal.component.html',
    styleUrls: ['./selection-detail-modal.component.scss'],
    standalone: false
})
export class SelectionDetailModalComponent implements OnInit {
    @Input() uuid?: string;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    selection: any = null;
    pages: { code: any; name: any; notify?: boolean; showSubscriptionAmount?: boolean; disabled: boolean }[];
    form: any;
    activePage: string;
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    subscriptionAmountSpinner: boolean = false;
    productARR: any[];
    tagARR: any[];
    categoryARR: any[];
    conditionCount: number = 1;
    subscriptionAmount: number = 0;
    loaded: boolean = false;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = false;
    submitting: boolean = false;

    tableHeads: any = [];

    listTypeOptions: any = [
        { title: 'Dynamische lijst', value: 'dynamic' }
        //{ title: 'Vaste lijst', value: 'static' }
    ];

    orderPeriodOptions = [
        { title: 'Afgelopen maand', value: 'last_month' },
        { title: 'Afgelopen jaar', value: 'last_year' }
    ];

    listARR: any = [];
    fullListARR: any = [];
    addRowFormSTR: any = {};
    filteredConditionOptions: any = [];
    conditionOptions: any = [];
    firstConditionOptions: any = [];

    selectedConditionOptions = [];
    ready: boolean = false;
    resourcesReady: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    initSideNavDisabled: boolean = false;

    formSTR: any = { type: 'dynamic', conditionARR: [] };

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        public ActiveModal: NgbActiveModal,
        public SelectionService: SelectionService,
        public ResourceService: ResourceService,
        public ProductService: ProductService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.uuid) {
            this.getSelection();
        } else {
            this.selection = {};
            this.ready = true;
            this.activeSection = 'conditions';
        }
        if (this.editMode) {
            this.activeSection = 'conditions';
        }
        // fetch resources
        this.initResources();
        // init pages
        this.initPages();

        // init list
        this.initListType();

        this.conditionOptions = this.initConditions();
        this.filteredConditionOptions = this.initConditions().filter((item) => {
            return item.value !== 'newslettertypeid';
        });
        this.firstConditionOptions = [this.initConditions()[0]];
        this.initFormSTRConditionARR();
    }

    initFormSTRConditionARR() {
        this.formSTR.conditionARR = [
            {
                condition: 'newslettertypeid',
                valueARR: [null]
            }
        ];
        this.setConditionOptionOptions(0);
    }

    isArray(arr) {
        return Array.isArray(arr);
    }

    getTestCondition() {
        let arr = [];
        let keysARR = this.formSTR.conditionARR.map((item) => {
            return item.condition;
        });

        for (let i = 0; i < this.conditionOptions.length; i++) {
            if (keysARR.includes(this.conditionOptions[i].value)) {
                arr.push(this.conditionOptions[i].value);
            }
        }
        return arr;
    }

    initConditions(): any {
        return [
            {
                title: 'Ingeschreven voor',
                value: 'newslettertypeid',
                type: 'dropdown',
                values: this.ResourceService.getResource('newsletterTypes').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.name), value: item.id };
                })
            },
            {
                title: 'Functie',
                value: 'functionid',
                type: 'dropdown',
                values: this.ResourceService.getResource('functions').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.description), value: item.id };
                })
            },
            {
                title: 'Branche',
                value: 'brancheid',
                type: 'dropdown',
                values: this.ResourceService.getResource('branches').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.description), value: item.id };
                })
            },
            {
                title: 'Bestelling product',
                value: 'productid',
                type: 'autocomplete',
                col: 'col-7',
                values: this.ResourceService.getResource('products').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.name), value: item.id };
                }),
                multipleSelect: true
            },
            {
                title: 'Bestelling periode',
                value: 'order_period',
                type: 'dropdown',
                values: this.orderPeriodOptions,
                singleValue: true
            },
            {
                title: 'Account manager',
                value: 'accountmanagerid',
                type: 'dropdown',
                values: this.ResourceService.getResource('accountManagers').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.name), value: item.id };
                })
            },
            {
                title: 'Kenmerk',
                value: 'tagid',
                type: 'dropdown',
                singleValue: true,
                values: this.ResourceService.getResource('tags').map((item) => {
                    return { title: this.HelpersService.titleCaseWord(item.description), value: item.id };
                })
            },
            { title: 'District', value: 'org_district', type: 'text' },
            { title: 'Locatie', value: 'org_city', type: 'text' },
            { title: 'Postcode', value: 'zipcode', type: 'text' },

            { title: 'Provincie', value: 'province', type: 'text' }
        ];
    }

    hasSingleValue(index: number) {
        var result = false;
        if (this.formSTR.conditionARR[index].condition) {
            result =
                this.conditionOptions.find((item) => {
                    return item.value === this.formSTR.conditionARR[index].condition;
                })?.singleValue === true;
        }
        return !result;
    }

    identify(index, item) {
        return index;
    }

    initListType() {
        this.tableHeads = [];
        if (this.formSTR.type == 'static') {
            this.tableHeads.push(
                { name: 'Email', code: 'email', sortable: false, canAddField: true },
                { name: 'Voornaam', code: 'firstname', sortable: false, canAddField: true },
                { name: 'Naam', code: 'lastname', sortable: false, canAddField: true },
                { name: '', code: 'empty' },
                { name: '', code: 'actions' }
            );
        } else {
            this.tableHeads.push(
                { name: 'Email', code: 'email', sortable: false, width: '25%' },
                { name: 'Naam', code: 'subscriber', sortable: false, width: '25%' },
                { name: 'Type', code: 'function', sortable: false, width: '25%' },
                { name: 'Ingeschreven op', code: 'subscribedTS', sortable: false },
                { name: '', code: 'actions' }
            );
        }
    }

    getActivePage() {
        return this.pages.find((item) => {
            return item.code === this.activePage;
        });
    }

    addCondition() {
        this.formSTR.conditionARR.push({ condition: undefined, valueARR: [undefined] });
    }

    addValue(index) {
        this.formSTR.conditionARR[index].valueARR = [...this.formSTR.conditionARR[index].valueARR, undefined];
    }

    deleteValue(conditionIndex, valueIndex) {
        this.changes = true;
        if (this.formSTR.conditionARR[conditionIndex].valueARR.length > 1) {
            this.formSTR.conditionARR[conditionIndex].valueARR.splice(valueIndex, 1);
            if (this.formSTR.conditionARR[conditionIndex].initialValueARR) {
                this.formSTR.conditionARR[conditionIndex].initialValueARR.splice(valueIndex, 1);
            }
        } else {
            this.formSTR.conditionARR.splice(conditionIndex, 1);
        }
        this.calculatesubscriptionAmount();
    }

    deleteCondition(index) {
        this.formSTR.conditionARR.splice(index, 1);
    }

    getSelection() {
        this.loading = true;
        this.SelectionService.getSelection(this.uuid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.selection = next.data.map((item) => {
                    const tempConditionObj: any = JSON.parse(item.filterJSON);
                    const conditionARR = this.formatConditionARR(tempConditionObj, true);

                    const selection = {
                        ...item,
                        conditionARR,
                        subscriptionAmountDisplay: null
                    };

                    return selection;
                })[0];

                this.resetForm();
                this.ready = true;
                this.loading = false;
            });
    }

    getColWidth(index: number) {
        if (this.formSTR?.conditionARR[index]?.condition) {
            const optionIndex = this.conditionOptions.findIndex((item) => {
                return item.value === this.formSTR.conditionARR[index].condition;
            });

            if (this.conditionOptions[optionIndex]?.col) {
                return this.conditionOptions[optionIndex].col;
            }
            return 'col-7';
        } else {
            return 'col-7';
        }
    }

    setConditionOptionOptions(index, init: boolean = true) {
        if (this.formSTR.conditionARR[index].condition) {
            const items = this.conditionOptions.find((item) => {
                return item.value === this.formSTR.conditionARR[index].condition;
            });

            if (items.values) {
                this.selectedConditionOptions[index] = items.values;
                if (items.type == 'autocomplete' && !this.formSTR.conditionARR[index].initialValueARR) {
                    this.formSTR.conditionARR[index].initialValueARR = [undefined];
                }
            }
            //Reset value for current condition
            if (init) {
                this.formSTR.conditionARR[index].valueARR = [undefined];
            }
        }
    }

    showFieldByType(index, type) {
        var result = false;
        if (this.formSTR.conditionARR[index].condition) {
            result =
                this.conditionOptions.find((item) => {
                    return item.value === this.formSTR.conditionARR[index].condition;
                })?.type === type;
        }
        return result;
    }

    showIfConditionSelected(index) {
        return (
            this.showFieldByType(index, 'dropdown') ||
            this.showFieldByType(index, 'text') ||
            this.showFieldByType(index, 'autocomplete') ||
            this.showFieldByType(index, 'taglist')
        );
    }

    async calculatesubscriptionAmount() {
        const filter = this.formatFilterJSON();
        this.subscriptionAmountSpinner = true;
        this.subscriptionAmount = await this.SelectionService.calculateSubscriptionsAmount(filter, this.uuid);
        this.SelectionService.setSelectionUuidForSubscriptionAmount(this.uuid);
        this.subscriptionAmountSpinner = false;
    }

    getListAndCalculate() {
        this.subscriptionAmountSpinner = true;
        this.getList();
        setTimeout(() => {
            this.subscriptionAmountSpinner = false;
        }, 500);
    }

    formChange() {
        this.changes = true;
        this.calculatesubscriptionAmount();
    }

    formatFilterJSON() {
        const conditionARR = this.formSTR.conditionARR || {};
        let result = {};

        for (let conditionItem of conditionARR) {
            const multipleSelect = this.conditionOptions.find((item) => {
                return item.value == conditionItem.condition;
            })?.multipleSelect;

            if (multipleSelect) {
                const filteredConditionARR = this.formSTR.conditionARR.filter((item) => {
                    return item.condition == conditionItem.condition;
                });

                const val = [];

                for (let i = 0; i < filteredConditionARR.length; i++) {
                    const valueARR = filteredConditionARR[i].valueARR;
                    const tempValueARR = [];
                    for (let j = 0; j < valueARR.length; j++) {
                        if (valueARR[j].toString().length > 0) {
                            tempValueARR.push(valueARR[j]);
                        }
                    }
                    if (tempValueARR.length == 1) {
                        val.push(tempValueARR[0]);
                    } else if (tempValueARR.length > 1) {
                        val.push(tempValueARR);
                    }
                }

                if (val.length) {
                    result[conditionItem.condition] = val;
                }
            } else {
                const val = conditionItem.valueARR.filter((item) => {
                    return item ? item.toString().length : false;
                });

                if (val.length) {
                    result[conditionItem.condition] = val;
                }
            }
        }
        return JSON.stringify(result);
    }

    resetForm() {
        if (this.selection.filterJSON) {
            const conditionARR = this.formatConditionARR(JSON.parse(this.selection.filterJSON), false);
            this.formSTR = { ...this.selection, conditionARR };
            for (let i = 0; i < conditionARR.length; i++) {
                this.setConditionOptionOptions(i, false);
            }
        }
        this.getList();
    }

    formatConditionARR(filterJSON: any, isDetail: boolean) {
        const conditionARR: any = [];

        for (let condition in filterJSON) {
            const currCondition = this.conditionOptions.find((subItem) => {
                return subItem.value === condition;
            });
            if (currCondition) {
                const name = isDetail ? currCondition.title : currCondition.value;
                let values = [];
                let initialValues = [];
                let skipLastInsert = false;

                if (currCondition.values && filterJSON[condition].length) {
                    //console.log(filterJSON[condition]);
                    for (let conditionItem of filterJSON[condition]) {
                        if (currCondition.multipleSelect) {
                            const tempValueARR = [];
                            const tempInitialValueARR = [];
                            if (!Array.isArray(conditionItem)) {
                                conditionItem = [conditionItem];
                            }
                            for (let i = 0; i < conditionItem.length; i++) {
                                const element = conditionItem[i];

                                const indexValues = currCondition.values.findIndex((subItem) => {
                                    return subItem.value === element;
                                });
                                if (indexValues >= 0) {
                                    if (isDetail) {
                                        tempValueARR.push(currCondition.values[indexValues].title);
                                    } else {
                                        tempValueARR.push(currCondition.values[indexValues].value);
                                    }

                                    if (currCondition.type == 'autocomplete' && !isDetail) {
                                        tempInitialValueARR.push({
                                            key: currCondition.values[indexValues].value,
                                            value: currCondition.values[indexValues].title
                                        });
                                    }
                                }
                            }
                            skipLastInsert = true;
                            let obj = {
                                condition: name,
                                valueARR: tempValueARR,
                                initialValueARR: tempInitialValueARR
                            };
                            conditionARR.push(obj);
                        } else {
                            const indexValues = currCondition.values.findIndex((subItem) => {
                                return subItem.value === conditionItem;
                            });
                            if (indexValues >= 0) {
                                if (isDetail) {
                                    values.push(currCondition.values[indexValues].title);
                                } else {
                                    values.push(currCondition.values[indexValues].value);
                                }

                                if (currCondition.type == 'autocomplete' && !isDetail) {
                                    initialValues.push({
                                        key: currCondition.values[indexValues].value,
                                        value: currCondition.values[indexValues].title
                                    });
                                }
                            }
                        }
                    }
                } else {
                    values = [...filterJSON[condition]];
                }
                if (!skipLastInsert) {
                    let obj = {
                        condition: name,
                        valueARR: values
                    };
                    if (initialValues.length) {
                        obj['initialValueARR'] = initialValues;
                    }
                    conditionARR.push(obj);
                }
            }
        }
        return [...conditionARR];
    }

    canEdit(sectionCode) {
        return sectionCode == 'conditions';
    }

    canDelete() {
        if (!this.sectionIsActive('conditions') && this.uuid) {
            return true;
        }
        return false;
    }

    createUpdateSelection() {
        const filterJSON = JSON.parse(this.formatFilterJSON());
        const FORM = { ...this.formSTR, filterJSON };

        if (this.uuid) {
            FORM.uuid = this.uuid;
        }

        this.saving = true;
        this.submitting = true;
        this.SelectionService.updateInsertSelection(FORM, this.uuid || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    if (!this.uuid) {
                        this.uuid = next['uuid'];
                        this.selection.uuid = this.uuid;
                        this.formSTR.uuid = this.uuid;
                        this.selection.name = next['name'];
                        this.created.emit(this.formSTR);
                    } else {
                        this.updated.emit(this.formSTR);
                    }
                    this.setSideNavDisabled(false);
                    this.activeSection = null;
                    this.getSelection();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    initResources() {
        const observables$: Observable<any>[] = [];

        const productObservable$ = this.ProductService.getProducts({}).pipe(
            tap((next: any) => {
                this.productARR = next.data.map((item) => {
                    return { ...item, value: item.name, key: item.id, code: item.artikelCode };
                });
            })
        );
        observables$.push(productObservable$);

        forkJoin(observables$).subscribe((next) => {
            this.resourcesReady = true;
        });

        this.resourcesReady = true;
    }

    sectionIsActive(code) {
        return this.activeSection == code;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    lastPageActive() {
        return this.activePage == this.pages[this.pages.length - 1].code;
    }

    nextPage() {
        const currentIndex = this.pages
            .map((page) => {
                return page.code;
            })
            .indexOf(this.activePage);
        if (currentIndex != -1) {
            this.pageChange(this.pages[currentIndex + 1].code);
        }
    }

    prevPage() {
        const currentIndex = this.pages
            .map((page) => {
                return page.code;
            })
            .indexOf(this.activePage);
        if (currentIndex != -1) {
            this.pageChange(this.pages[currentIndex - 1].code);
        }
    }

    pageChange($event) {
        if (this.activePage === $event || !this.canExit()) return;
        this.tableLoading = true;
        this.activeSection = null;
        this.activePage = $event;
        if (this.activePage == 'list') {
            this.getList();
        }
        setTimeout(() => {
            this.tableLoading = false;
        }, 500);
    }

    initPages() {
        let pages = [
            { code: 'conditions', name: 'Voorwaarden', disabled: this.initSideNavDisabled, icon: 'settings-outline' },
            {
                code: 'list',
                name: 'Lijst',
                showSubscriptionAmount: true,
                disabled: this.initSideNavDisabled,
                icon: 'editor-ul'
            }
        ];
        this.pages = pages;
        this.activePage = this.pages[0].code;
    }

    setSideNavDisabled(disabled: boolean) {
        this.pages = this.pages.map((item) => {
            item.disabled = disabled;
            return { ...item };
        });
    }

    // addRowToTable(test: any) {
    //     //save and get list again

    //     //endpoint adduser
    //     //addrowvalidation = error
    //     this.list.push(test);

    //     this.addRowFormSTR = {};

    //     this.initList();
    // }

    getList() {
        this.tableLoading = true;
        this.subscriptionAmountSpinner = true;
        const showAll = this.formSTR.type == 'static' ? false : true;
        const searchSTR = {
            startRow: this.startRow,
            RPP: this.RPP,
            showAll: showAll
        };
        if (this.activeSection == 'conditions') {
            searchSTR['filterJSON'] = this.formatFilterJSON();
        }
        if (this.uuid) {
            searchSTR['selectionUuids'] = [this.uuid];
        }
        this.SelectionService.getSelectionSubscriptions(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next: any) => {
                this.listARR = next.data.map((item) => {
                    const actions = [];
                    let deleteAction = {};
                    if (item.inBlocklist) {
                        deleteAction = {
                            name: 'Verwijder ongedaan maken',
                            code: 'deleteReverse',
                            icon: 'trash',
                            class: 'delete-red',
                            confirmDelete: false
                        };
                    } else {
                        deleteAction = {
                            name: 'Verwijderen',
                            code: 'delete',
                            icon: 'trash',
                            class: 'delete-red',
                            confirmDelete: false
                        };
                    }
                    actions.push(deleteAction);

                    return {
                        ...item,
                        subscribedTS: moment(item.subscribedTS).format('DD MMMM YYYY'),
                        email: {
                            type: 'default',
                            color: 'black',
                            value: item.email,
                            subscribeTS: { type: 'ts', ts: item.subscribeTS }
                        },
                        linethrough: item.inBlocklist ? true : false,
                        actions: {
                            type: 'actions',
                            actions: actions
                        }
                    };
                });
                this.totalRows = next.rows;
                this.calculatesubscriptionAmount();
                setTimeout(() => {
                    this.subscriptionAmountSpinner = false;
                    this.tableLoading = false;
                }, 500);
            });
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getList();
    }

    toggleBlockList(id: number, inBlocklist: boolean) {
        this.dismissPopover();
        this.SelectionService.toggleBlockList(this.uuid, id, inBlocklist).subscribe((next) => {
            this.getList();
        });
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                console.log('edit');
                // this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.toggleBlockList(item.id, true);
                break;
            case 'deleteReverse':
                this.toggleBlockList(item.id, false);
                break;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
