import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '../../../../services/helpers.service';

@Component({
    selector: 'app-editor-link',
    templateUrl: './editor-link.component.html',
    styleUrls: ['./editor-link.component.scss'],
    standalone: false
})
export class EditorLinkComponent implements OnInit {
    @Output() confirmed = new EventEmitter();
    @Input() formSTR: any = { type: 'normal_link', text: '', url: '' };
    @Input() linkTypes: string[] = [];
    linkTypeOptions: any[] = [
        { title: 'Link', value: 'normal_link' },
        { title: 'Knop', value: 'button_link' },
        { title: 'E-mailadres', value: 'email_link' }
    ];

    constructor(public ActiveModal: NgbActiveModal, private HelpersService: HelpersService) {}

    ngOnInit(): void {
        if (this.linkTypes?.length) {
            this.linkTypeOptions = this.linkTypeOptions.filter((option) => this.linkTypes.includes(option.value));
        }
    }

    canSubmit() {
        if (!this.formSTR?.type) return;
        switch (this.formSTR.type) {
            case 'normal_link':
            case 'button_link':
            case 'email_link':
                return this.formSTR.url;
        }
        return true;
    }

    submit() {
        if (!this.canSubmit()) {
            return;
        }
        if (this.formSTR.url) {
            switch (this.formSTR.type) {
                case 'normal_link':
                case 'button_link':
                    this.formSTR.url = this.HelpersService.fillHttp(this.formSTR.url);
                    break;
                case 'email_link':
                    this.formSTR.url = `mailto:${this.formSTR.url}`;
                    break;
            }
        }
        if (!this.formSTR.text) {
            this.formSTR.text = this.formSTR.url;
        }
        this.confirmed.emit(this.formSTR);
        this.ActiveModal.dismiss();
    }

    getLabel() {
        switch (this.formSTR.type) {
            case 'normal_link':
            case 'button_link':
                return 'Webadres';
            case 'email_link':
                return 'E-mailadres';
        }
    }

    getPlaceholder() {
        switch (this.formSTR.type) {
            case 'normal_link':
            case 'button_link':
                return 'www.voorbeeld.com';
            case 'email_link':
                return 'naam@voorbeeld.com';
        }
    }
}
