import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-arr-show-more',
    templateUrl: './arr-show-more.component.html',
    styleUrls: ['./arr-show-more.component.scss'],
    standalone: false
})
export class ArrShowMoreComponent {
    @Input() items: string[] = [];
    @Input() showAmount?: number = 1;
    @Input() tip: string | string[] = [];

    display: string = '';

    constructor() {}

    ngOnInit(): void {
        // getItems()[0] | display
        // const res = [];
        this.display = this.getItems().slice(0, this.showAmount).join(', ');
    }

    ngOnChanges(): void {
        this.items = this.getItems();
        this.display = this.getItems().slice(0, this.showAmount).join(', ');
        this.tip = this.getTip();
    }

    getTip() {
        if (!Array.isArray(this.items)) return [];
        return this.items.slice(this.showAmount, this.items.length).join(', ');
    }

    getItems() {
        if (!Array.isArray(this.items)) return [];
        return this.items ? this.items : [];
    }
}
