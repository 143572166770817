import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-delete',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss'],
    standalone: false
})
export class ConfirmDeleteComponent implements OnInit {
    @Input() open?: boolean;
    @Input() positionClass?: 'left' | 'right' | 'center' = 'center';
    @Input() positionClassV?: 'top' | 'bottom' | 'middle' = 'middle';
    @Input() title?: string;
    @Input() descr?: string;
    @Input() cancelButton?: string = 'Nee, annuleren';
    @Input() confirmButton?: string = 'Ja, verwijderen';

    defaultTitle: string = 'Ben je zeker?';
    defaultDescr: string = 'Ben je zeker dat je dit item wil verwijderen? Deze actie kan niet ongedaan gemaakt worden.';

    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.title = this.title ? this.title : this.defaultTitle;
        this.descr = this.descr ? this.descr : this.defaultDescr;
    }
}
