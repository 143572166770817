import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-hotline-medicines',
    templateUrl: './hotline-medicines.component.html',
    styleUrl: './hotline-medicines.component.scss',
    standalone: false
})
export class HotlineMedicinesComponent implements OnInit {
    helpersService = inject(HelpersService);
    configService = inject(ConfigService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);

    SORT = { code: 'createTS', dir: 'desc' };
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    ready: boolean = false;
    loading: boolean = false;
    appOverviewUrl: string;
    uploadingFile: boolean = false;
    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Bestand', code: 'bestandNaam', sortable: false, width: '55%' },
        { name: 'Aantal medicijnen', code: 'aantalMedicijnen', sortable: false, width: '15%' },
        { name: 'Geupload op', code: 'createTS', sortable: false, width: '15%' },
        { name: 'Gepubliceerd', code: 'createUsername', sortable: false, width: '15%' }
    ];

    data: any;
    error: boolean = false;

    ngOnInit() {
        this.appOverviewUrl = this.configService.getConfig().appOverviewUrl;
        this.getMedicineHistory();
    }

    async getMedicineHistory() {
        this.loading = true;
        await lastValueFrom(
            this.defaultService.meldpunMedicijnenGeschiedenisGetMedicinesHistory(this.startRow, this.RPP, [
                `${this.SORT.code} ${this.SORT.dir}`
            ])
        ).then((res) => {
            this.totalRows = res.rows;
            this.data = res?.data?.map((medicine) => {
                return {
                    ...medicine,
                    id: medicine.id,
                    bestandNaam: {
                        type: 'default',
                        classList: 'text-color',
                        value: medicine.bestandNaam,
                        ellipsis: true
                    },
                    aantalMedicijnen: {
                        type: 'default',
                        value: medicine.aantalMedicijnen
                    },
                    createUsername: {
                        type: 'default',
                        value: medicine.createUsername
                    },
                    createTS: {
                        type: 'ts',
                        ts: medicine.createTS,
                        format: 'prettyDateTime'
                    }
                };
            });
        });

        this.loading = false;
        this.ready = true;
    }

    async setStartRow(startRow: number) {
        this.startRow = startRow;
        await this.getMedicineHistory();
        document.documentElement.scrollTop = 0;
    }

    selectFile(file: File) {
        this.uploadingFile = true;
        this.defaultService.meldpunMedicijnenGeschiedenisUploadMedicineList(file).subscribe({
            next: async (next) => {
                this.toastr.success('Bestand succesvol geupload', 'Bestand is geupload');
                this.loading = true;
                await this.getMedicineHistory();
                this.uploadingFile = false;
            },
            error: (error) => {
                this.uploadingFile = false;
                this.error = true;
            }
        });
    }

    downloadFile(item) {
        this.helpersService.downloadLink(item.bestandUrl);
    }
}
