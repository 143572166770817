import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { catchError, distinctUntilChanged, map, of, shareReplay } from 'rxjs';
import { PopupDetailModalComponent } from 'src/app/components/modals/hotlines/popup-detail-modal/popup-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';
type Sort = { code: string; dir: 'asc' | 'desc' };
type Popup = {
    id?: string;
    titel: {
        type: string;
        classList: string;
        value: string;
        ellipsis: boolean;
    };
    editTS: {
        type: string;
        ts: string;
        format: string;
    };
    createTS: {
        type: string;
        ts: string;
        format: string;
    };
    editUsername: {
        type: string;
        value: string;
    };
    actions: {
        type: string;
        actions: any;
    };
    actief: { type: string; checked: boolean };
};

@Component({
    selector: 'app-hotline-notifications',
    templateUrl: './hotline-notifications.component.html',
    styleUrl: './hotline-notifications.component.scss',
    standalone: false
})
export class HotlineNotificationsComponent implements OnInit {
    configService = inject(ConfigService);
    helpersService = inject(HelpersService);
    searchService = inject(SearchService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    defaultService = inject(DefaultService);
    modalService = inject(NgbModal);
    toastr = inject(ToastrService);

    appOverviewUrl: string;
    SEARCH: { term?: string } = {};
    SEARCHED: any[] = [];
    SORT: Sort = { code: 'actief', dir: 'desc' };
    // SEARCHED: { term?: string } = {};
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    ready: boolean = false;
    loading: boolean = false;

    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Titel', code: 'titel', sortable: true, width: '70%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '7%' },
        { name: 'Actief', code: 'actief', sortable: true, width: '2%' },
        { name: 'Gewijzigd op', code: 'editTS', sortable: true, width: '7%' },
        { name: 'Gewijzigd door', code: 'editUsername', sortable: true, width: '7%' },
        { name: '', code: 'actions', sortable: false }
    ];
    data: Popup[];

    newsDetailModal: NgbModalRef;
    popupDetail$ = this.route.queryParamMap.pipe(
        map((paramMap: ParamMap) => {
            const id = paramMap.get('popupId');
            const mode = paramMap.get('mode');

            return mode || id
                ? {
                      id: id,
                      mode: !id ? 'create' : mode
                  }
                : undefined;
        }),
        distinctUntilChanged(),
        catchError((error) => {
            return of();
        }),
        shareReplay(1)
    );

    constructor() {
        // newsDetailModal
        this.popupDetail$.subscribe((next) => {
            if (this.newsDetailModal?.componentInstance) {
                this.newsDetailModal.dismiss();
                this.newsDetailModal = null;
            }

            if (next) {
                this.newsDetailModal = this.modalService.open(PopupDetailModalComponent, {
                    windowClass: 'main-modal detail-modal',
                    beforeDismiss: () => {
                        return (
                            this.newsDetailModal.componentInstance.canExit &&
                            this.newsDetailModal.componentInstance.canExit()
                        );
                    }
                });

                this.newsDetailModal.componentInstance.updateOverview.subscribe((next) => this.getPopups());

                this.newsDetailModal.dismissed.subscribe({
                    next: (next) => {
                        this.router.navigate([], {
                            queryParams: {
                                popupId: undefined,
                                mode: undefined
                            },
                            queryParamsHandling: 'merge'
                        });
                    }
                });
            }
        });
    }

    ngOnInit(): void {
        this.appOverviewUrl = this.configService.getConfig().appOverviewUrl;
        let searchQuery = this.route.snapshot.queryParamMap.get('query');
        let term: string;
        if (searchQuery) term = JSON.parse(searchQuery).term;
        this.SEARCH = {
            term: term
        };
        this.getPopups();
    }

    async getPopups() {
        this.SEARCHED = await this.searchService.formatTags(this.SEARCH, { term: undefined });

        this.loading = true;

        this.defaultService
            .meldpuntPopupsGetPopups(this.startRow, this.RPP, [`${this.SORT.code} ${this.SORT.dir}`], this.SEARCH.term)
            .subscribe({
                next: (next) => {
                    const actions = [
                        {
                            name: 'Wijzigen',
                            code: 'edit',
                            icon: 'pencil'
                        },
                        {
                            name: 'Verwijderen',
                            code: 'delete',
                            icon: 'trash',
                            class: 'delete-red',
                            confirmDelete: true,
                            title: 'Weet je het zeker?',
                            descr: 'Weet je zeker dat je dit item wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.'
                        }
                    ];

                    this.totalRows = next.rows;
                    this.data = next.data?.map((notification) => {
                        return {
                            ...notification,
                            titel: {
                                type: 'default',
                                classList: 'text-color',
                                value: notification.titel,
                                ellipsis: true
                            },
                            editTS: {
                                type: 'ts',
                                ts: notification.editTS,
                                format: 'DD-MM-YYYY '
                            },
                            createTS: {
                                type: 'ts',
                                ts: notification.createTS,
                                format: 'DD-MM-YYYY '
                            },
                            editUsername: {
                                type: 'default',
                                value: notification.editUsername
                            },
                            actions: {
                                type: 'actions',
                                actions: actions
                            },
                            actief: { type: 'checkmark', checked: notification.actief }
                        };
                    });

                    this.loading = false;
                    this.ready = true;
                }
            });
    }

    openDetail(item: any, mode?: string) {
        this.router.navigate([], {
            queryParams: {
                popupId: item?.id,
                mode: mode
            }
        });
    }

    actionClick(item: Popup, action: string) {
        switch (action) {
            case 'edit':
                this.openDetail(item);
                break;
            case 'delete':
                this.loading = true;
                this.defaultService.meldpuntPopupsDeletePopup(item.id).subscribe({
                    next: (next) => {
                        this.loading = false;
                        this.toastr.success('Popup werd succesvol verwijderd', 'Popup verwijderd');
                        this.getPopups();
                    },
                    error: (error) => {
                        this.loading = false;
                        this.toastr.error('Popup kon niet worden verwijderd', 'Popup verwijderen mislukt');
                    }
                });
                break;
        }
    }

    setStartRow(startRow: number) {
        this.startRow = startRow;
        this.search();
        document.documentElement.scrollTop = 0;
    }

    setSort(code: string, dir: 'asc' | 'desc') {
        this.SORT = { code: code, dir: dir };
        this.getPopups();
    }

    resetSearch() {
        this.SEARCH = {};
        this.startRow = 0;
    }

    search = (reset?: boolean, SEARCH?: any) => {
        if (reset) this.resetSearch();
        SEARCH = SEARCH || this.SEARCH;
        this.SEARCH = { ...SEARCH };

        if (this.helpersService.objectIsEmpty(SEARCH)) {
            this.router.navigate([], {
                queryParams: {}
            });
        } else {
            const query = JSON.stringify(SEARCH);

            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(SEARCH),
                    page: (Math.ceil(this.startRow / this.RPP) + 1).toString()
                },
                queryParamsHandling: 'merge'
            });
        }

        this.getPopups();
    };
    openCreateUpdateModal() {}
}
