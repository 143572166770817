import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { switchPanelAnimation } from 'src/app/utils/animations';
import { HelpersService } from '../../services/helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { ConfigService } from 'src/app/services/config.service';
import { ActiveSubstanceService } from 'src/app/services/active-substance.service';
import { ActiveSubstanceDetailModalComponent } from 'src/app/components/modals/active-substance-detail-modal/active-substance-detail-modal.component';
import { DefaultService } from 'src/app/utils/api';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';

@Component({
    selector: 'app-active-substances',
    templateUrl: './active-substances.component.html',
    styleUrls: ['./active-substances.component.scss'],
    animations: [switchPanelAnimation],
    standalone: false
})
export class ActiveSubstancesComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};

    exportBusy: boolean = false;

    // table
    tableHeads: any = [
        { name: 'Stofnaam', code: 'name', sortable: true, width: '20%' },
        { name: 'Merknamen', code: 'brand', sortable: true, width: '24%' },
        { name: 'Medicijngroep', code: 'medgroup', sortable: true, width: '24%' },
        { name: 'Categorie', code: 'category', sortable: true, width: '16%' },
        { name: 'Gepubliceerd', code: 'isPublished', sortable: true, width: '.1%' },
        { name: 'Laatst gewijzigd', code: 'lastModifiedTS', sortable: true, width: '16%' },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'name', dir: 'asc' };

    activeSubstances: any[] = null;
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    tableLoading: boolean = true;
    ready: boolean = false;

    categoryARR: any[];

    constructor(
        private ModalService: NgbModal,
        private ActiveSubstanceService: ActiveSubstanceService,
        public HelpersService: HelpersService,
        public route: ActivatedRoute,
        public router: Router,
        private SearchService: SearchService,
        public ConfigService: ConfigService,
        private DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        const activeActiveSubstanceId = this.HelpersService.getParam('werkzameStofID');
        if (activeActiveSubstanceId) this.openCreateUpdateModal(activeActiveSubstanceId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getActiveSubstances();
    }

    deleteActiveSubstance(id: number) {
        this.dismissPopover();
        this.deSelectRows();
        this.ActiveSubstanceService.deleteActiveSubstance(id).subscribe((next) => {
            this.getActiveSubstances();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getDefaultSearch() {
        return {
            activeSubstance: []
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getActiveSubstances();
    };

    getActiveSubstances() {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.activeSubstancesGetActiveSubstances(
                searchSTR.startRow,
                searchSTR.rRP,
                searchSTR.term,
                searchSTR.orderBy
            )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.activeSubstances = next.data.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    this.totalRows = next.rows;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
        });
    }

    getFormattedItem(item) {
        const actions = [];
        if (!item.used) {
            const editAction = {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            };
            actions.push(editAction);
        }
        if (!item.used) {
            const deleteAction = {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            };
            actions.push(deleteAction);
        }
        let substanceClassName = 'text-color';

        return {
            ...item,
            name: {
                type: 'default',
                classList: substanceClassName,
                value: item.name
            },
            actions: {
                type: 'actions',
                actions: actions
            },
            lastModifiedTS: {
                type: 'ts',
                ts: item.lastModifiedTS,
                format: 'DD-MM-YYYY'
            },
            brand: {
                type: 'arrayShowMore',
                values: item?.brand?.filter((b) => b.name).map((b) => b.name),
                showAmount: 2
            },
            isPublished: { type: 'checkmark', checked: item.isPublished === 1 ? true : false }
        };
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getActiveSubstances();
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getActiveSubstances();
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteActiveSubstance(item.id);
                break;
        }
    }

    selectRow(item: any) {
        this.activeSubstances = this.activeSubstances.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    deSelectRows() {
        if (this.activeSubstances) {
            this.activeSubstances = this.activeSubstances.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    setSort(code: string, dir: string) {
        this.tableLoading = true;
        this.tableSort = { code: code, dir: dir };
        this.getActiveSubstances();
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(ActiveSubstanceDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('werkzameStofID', id);
            //add pathparam to url
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        } else this.HelpersService.addParam('werkzameStofID', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getActiveSubstances();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('werkzameStofID');
                setTimeout(() => {
                    this.getActiveSubstances();
                }, 1000);
            });
        // on create
        modalRef.componentInstance.created.subscribe((ActiveSubstance: any) => {
            this.HelpersService.addParam('werkzameStofID', ActiveSubstance.id);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((ActiveSubstance: any) => {
            this.getActiveSubstances();
            this.HelpersService.removeParam('werkzameStofID');
        });
    }

    resetSearch() {
        this.searchSTR.term = '';
        this.HelpersService.removeParam('search');
    }

    export() {
        // const searchSTR = this.getSearchQuery();
        // let params = new HttpParams();
        // for (const key in searchSTR) {
        //     params = params.append(key, searchSTR[key]);
        // }
        this.exportBusy = true;
        this.DefaultService.activeSubstancesExportActiveSubstances().subscribe((data) => {
            this.HelpersService.downloadBlob(data, `werkzame-stoffen-${moment().format('YYYY-MM-DD')}.xlsx`);
            setTimeout(() => {
                this.exportBusy = false;
            }, 500);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
