import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-editor-add-block',
    templateUrl: './editor-add-block.component.html',
    styleUrls: ['./editor-add-block.component.scss'],
    standalone: false
})
export class EditorAddBlockComponent implements OnInit {
    @Input() model: any;
    @Input() types: any[];
    @Output() add = new EventEmitter();

    active: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    addBlock(type: string) {
        let block: any = { type: type };
        switch (type) {
            case 'text':
                block = { ...block, html: '' };
                break;
            case 'text-right':
                block = { ...block, html: '', fileId: null, url: '' };
                break;
            case 'text-left':
                block = { ...block, html: '', fileId: null, url: '' };
                break;
            case 'image':
                block = { ...block, html: '', fileId: null, url: '' };
                break;
            case 'video':
                block = { ...block, html: '', fileId: null, url: '' };
                break;
        }
        this.add.emit(block);
        this.active = false;
    }
}
