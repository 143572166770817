import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-profile-section',
    templateUrl: './profile-section.component.html',
    styleUrls: ['./profile-section.component.scss'],
    standalone: false
})
export class ProfileSectionComponent implements OnInit {
    appName: string;
    routes: any;
    user: any;
    user$: any;
    profileOpen: boolean = null;

    constructor(public AuthenticationService: AuthenticationService) {}

    setProfileOpen(value: boolean) {
        setTimeout(() => {
            this.profileOpen = value;
        }, 1);
    }

    ngOnInit(): void {
        this.AuthenticationService.getUser().subscribe((next: any) => {
            this.user = { ...next };
        });
    }

    toggleProfileOpen() {
        if (this.profileOpen) {
            this.profileOpen = null;
        } else this.profileOpen = true;
    }
}
