import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-search-radios',
    templateUrl: './search-radios.component.html',
    styleUrls: ['./search-radios.component.scss'],
    standalone: false
})
export class SearchRadiosComponent implements OnChanges {
    @Input() options: any[] = [];
    @Input() model: string = undefined;
    @Input() placeholder: string = '';
    @Input() allowClear: boolean = true;
    @Output() modelChange = new EventEmitter();

    helper: any = {
        selected: null
    };

    @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {}

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.setSelected();
    }

    setSelected() {
        if (this.options) {
            this.helper.selected = this.options.find((item) => {
                return item.value === this.model;
            });
        } else this.helper.selected = undefined;
    }

    openForm(popover?: NgbPopover) {
        this.helper.FORM = JSON.parse(JSON.stringify({ model: this.model || undefined }));
        this.helper.FORM.popover = popover;
    }

    select(option) {
        if (option.value === this.helper.FORM.model) {
            this.helper.FORM.model = undefined;
        } else this.helper.FORM.model = option.value;
        this.modelChange.emit(this.helper.FORM.model);
    }

    clear() {
        this.modelChange.emit(undefined);
        if (this.helper.FORM.popover) this.helper.FORM.popover.close();
        this.helper.FORM = null;
    }
}
