import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirm-send',
    templateUrl: './confirm-send.component.html',
    styleUrls: ['./confirm-send.component.scss'],
    standalone: false
})
export class ConfirmSendComponent implements OnInit {
    @Output() confirm = new EventEmitter();

    constructor(public ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {}
}
