import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';

@Component({
    selector: 'app-field-textarea',
    templateUrl: './field-textarea.component.html',
    styleUrls: ['./field-textarea.component.scss'],
    standalone: false
})
export class FieldTextareaComponent {
    @Input() label?: string = '';
    @Input() error?: string;
    @Input() placeholder?: string = '';
    @Input() model: string = '';
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();

    constructor() {}
}
