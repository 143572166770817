import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { ContactService } from 'src/app/services/contact.service';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
    selector: 'app-contact-detail-modal',
    templateUrl: './contact-detail-modal.component.html',
    styleUrls: ['./contact-detail-modal.component.scss'],
    standalone: false
})
export class ContactDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Output() updated = new EventEmitter();
    ready: boolean = false;
    contact: any = null;
    saving: boolean = false;

    onDestroy$ = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        private ContactService: ContactService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit() {
        this.getContact();
    }

    delete() {
        this.saving = true;
        this.ContactService.deleteContact(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.updated.emit(this.contact);
                this.ActiveModal.dismiss();
            });
    }

    getContact() {
        if (!this.id) return;
        this.ContactService.getContact(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.contact = res;
                this.ready = true;
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
