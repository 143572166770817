import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { QuestionsService } from 'src/app/services/questions.service';

@Component({
    selector: 'app-question-detail-modal',
    templateUrl: './question-detail-modal.component.html',
    styleUrls: ['./question-detail-modal.component.scss'],
    standalone: false
})
export class QuestionDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    question: any = {};
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = {};

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        public questionService: QuestionsService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.id && this.id !== 'new') {
            this.getQuestion();
        } else {
            this.question = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }

    getQuestion() {
        this.loading = true;
        this.questionService
            .getQuestion(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.question = res;
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.ready = true;
                this.loading = false;
                this.resetForm();
            });
    }

    createUpdateQuestion() {
        const FORM = this.formSTR;
        this.submitting = true;
        if (FORM.isPublished === 1 && !FORM.publishDate) {
            FORM.publishDate = new Date();
        }
        if (FORM.isPublished === 2) {
            FORM.publishDate = null;
        }
        this.saving = true;
        this.questionService
            .updateInsertQuestion(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.submitting = false;
                    this.saving = false;
                })
            )
            .subscribe({
                next: (res) => {
                    this.validation = {};
                    this.changes = false;
                    this.question.id ? this.updated.emit(this.question) : this.created.emit(this.question);
                    this.ActiveModal.close();
                },
                error: (err) => {
                    this.validation = err.error.details;
                }
            });
    }
    getPublishedText() {
        if (this.question.isPublished === 1) {
            return 'Ja';
        }
        if (this.question.isPublished === 2) {
            return 'Nee';
        }
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.questionService
            .deleteQuestion(this.question.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.question);
                this.changes = false;
                this.ActiveModal.dismiss();
            });
    }

    formChange() {
        this.changes = true;
    }

    getHomePageText() {
        return this.question.homePage ? 'Ja' : 'Nee';
    }

    resetForm() {
        this.formSTR = { ...this.question };
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
