import { Component, inject, OnInit } from '@angular/core';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { catchError, distinctUntilChanged, map, of, shareReplay } from 'rxjs';
import {
    HotlineNewsDetailModalComponent,
    NewsDetailMode
} from 'src/app/components/modals/hotlines/hotline-news-detail-modal/hotline-news-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';
import Sortable from 'sortablejs';
import { ToastrService } from 'ngx-toastr';
type Sort = { code: string; dir: 'asc' | 'desc' };
export type MeldpuntNieuwsitem = {
    id: string;
    titel: {
        type: string;
        classList: string;
        value: string;
        ellipsis?: boolean;
    };
    actions: {
        type: string;
        actions: any;
    };
    datum: {
        type: string;
        ts: string;
        format: string;
    };
    editUsername: {
        type: string;
        value: string;
    };
    createTS: {
        type: string;
        ts: string;
        format: string;
    };
    gepubliceerd: { type: string; checked: boolean };
};

@Component({
    selector: 'app-hotline-news',
    templateUrl: './hotline-news.component.html',
    styleUrl: './hotline-news.component.scss',
    standalone: false
})
export class HotlineNewsComponent implements OnInit {
    configService = inject(ConfigService);
    helpersService = inject(HelpersService);
    searchService = inject(SearchService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    defaultService = inject(DefaultService);
    modalService = inject(NgbModal);
    toastr = inject(ToastrService);

    SEARCH: { term?: string } = {};
    SEARCHED: any[] = [];
    SORT: Sort = { code: 'sequence', dir: 'asc' };
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    ready: boolean = false;
    loading: boolean = false;
    editSequence: boolean = false;
    sortableContainer: Sortable;

    tableHeads: {
        name: string;
        code: string;
        sortable: boolean;
        width?: string;
    }[] = [
        { name: 'Titel', code: 'titel', sortable: true, width: '70%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '7%' },
        { name: 'Gepubliceerd', code: 'gepubliceerd', sortable: true, width: '2%' },
        { name: 'Gewijzigd op', code: 'datum', sortable: true, width: '7%' },
        { name: 'Gewijzigd door', code: 'editUsername', sortable: true, width: '7%' },
        { name: '', code: 'actions', sortable: false }
    ];
    data: MeldpuntNieuwsitem[];
    appOverviewUrl: string;

    newsDetailModal: NgbModalRef;
    newsDetail$ = this.route.queryParamMap.pipe(
        map((paramMap: ParamMap) => {
            const id = paramMap.get('newsId');
            const mode = paramMap.get('mode');

            return mode || id
                ? {
                      id: id,
                      mode: !id ? 'create' : mode
                  }
                : undefined;
        }),
        distinctUntilChanged(),
        catchError((error) => {
            return of();
        }),
        shareReplay(1)
    );

    constructor() {
        // newsDetailModal
        this.newsDetail$.subscribe((next) => {
            if (this.newsDetailModal?.componentInstance) this.newsDetailModal.dismiss();

            if (next) {
                this.newsDetailModal = this.modalService.open(HotlineNewsDetailModalComponent, {
                    windowClass: 'main-modal detail-modal',
                    beforeDismiss: () => {
                        return (
                            this.newsDetailModal.componentInstance.canExit &&
                            this.newsDetailModal.componentInstance.canExit()
                        );
                    }
                });

                this.newsDetailModal.dismissed.subscribe({
                    next: (next) => {
                        this.router.navigate([], {
                            queryParams: next?.queryParams ?? {
                                newsId: undefined,
                                mode: undefined
                            },
                            queryParamsHandling: 'merge'
                        });
                    }
                });

                // this.newsDetailModal.closed.subscribe({
                //     next: (next) => {
                //         this.router.navigate([], {
                //             queryParams: next?.queryParams ?? {
                //                 newsId: undefined,
                //                 mode: undefined
                //             },
                //             queryParamsHandling: 'merge'
                //         });
                //     }
                // });

                this.newsDetailModal.componentInstance.updateOverview.subscribe((next) => this.getNews());
            }
        });
    }

    ngOnInit(): void {
        this.appOverviewUrl = this.configService.getConfig().appOverviewUrl;
        let searchQuery = this.route.snapshot.queryParamMap.get('query');
        let term: string;
        if (searchQuery) term = JSON.parse(searchQuery).term;
        this.SEARCH = {
            term: term
        };
        this.getNews();
    }

    async getNews(showAll: boolean = false) {
        this.SEARCHED = await this.searchService.formatTags(this.SEARCH, { term: undefined });
        this.loading = true;

        const newsItems = await lastValueFrom(
            this.defaultService.meldpuntNewsitemsGetNewsitems(
                this.startRow,
                showAll ? undefined : this.RPP,
                [`${this.SORT.code} ${this.SORT.dir}`],
                this.SEARCH?.term
            )
        );

        showAll ? (this.RPP = newsItems.rows) : this.setRPP();
        this.totalRows = newsItems.rows;
        this.data = newsItems?.data?.map((newsitem) => {
            const actions = [];
            const editAction = {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            };
            actions.push(editAction);
            const deleteAction = {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true,
                title: 'Weet je het zeker?',
                descr: 'Weet je zeker dat je dit item wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.'
            };
            actions.push(deleteAction);

            return {
                id: newsitem.id,
                titel: {
                    type: 'default',
                    classList: 'text-color',
                    value: newsitem.titel,
                    ellipsis: true
                },
                actions: {
                    type: 'actions',
                    actions: actions
                },
                datum: {
                    type: 'ts',
                    ts: newsitem.datum ? moment(newsitem.datum).format('DD-MM-YYYY') : undefined,
                    format: 'DD-MM-YYYY'
                },
                editUsername: {
                    type: 'default',
                    value: newsitem.editUsername
                },
                createTS: {
                    type: 'ts',
                    ts: moment(newsitem.createTS).format('DD-MM-YYYY'),
                    format: 'DD-MM-YYYY '
                },
                gepubliceerd: { type: 'checkmark', checked: newsitem.gepubliceerd }
            };
        });

        this.loading = false;
        this.ready = true;
    }

    openDetailModal(mode?: NewsDetailMode, item?: MeldpuntNieuwsitem) {
        this.router.navigate([], {
            queryParams: {
                newsId: item?.id,
                mode: mode ? mode : 'view'
            },
            queryParamsHandling: 'merge'
        });
    }

    async actionClick(item: MeldpuntNieuwsitem, action: string) {
        switch (action) {
            case 'edit':
                this.openDetailModal('edit', item);
                break;
            case 'delete':
                this.defaultService.meldpuntNewsitemsDeleteNewsitem(item.id).subscribe({
                    next: (next) => {
                        this.toastr.success('Nieuwsitem werd successvol verwijderd', 'Nieuwsitem verwijderd');
                        this.getNews();
                    }
                });
                break;
        }
    }

    setStartRow(startRow: number) {
        this.startRow = startRow;
        this.search();
        document.documentElement.scrollTop = 0;
    }

    setRPP() {
        this.RPP = 50;
    }

    setSort(code: string, dir: 'asc' | 'desc') {
        this.SORT = { code: code, dir: dir };
        this.getNews();
    }

    resetSearch() {
        this.SEARCH = {};
        this.startRow = 0;
    }

    search = (reset?: boolean, SEARCH?: any) => {
        if (reset) this.resetSearch();
        SEARCH = SEARCH || this.SEARCH;
        this.SEARCH = { ...SEARCH };

        if (this.helpersService.objectIsEmpty(SEARCH)) {
            this.router.navigate([], {
                queryParams: {}
            });
        } else {
            const query = JSON.stringify(SEARCH);

            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(SEARCH),
                    page: (Math.ceil(this.startRow / this.RPP) + 1).toString()
                },
                queryParamsHandling: 'merge'
            });
        }

        this.getNews();
    };

    initSortable() {
        const el: HTMLElement = document.querySelector('#sortable');
        this.sortableContainer?.destroy();
        if (el) {
            let that = this;
            this.sortableContainer = new Sortable(el, {
                animation: 150,
                dragClass: 'sortable-dragging',
                ghostClass: 'sortable-ghost',
                forceFallback: true,
                handle: '.sortable-handle',
                direction: 'vertical',
                disabled: !that.changeSequence,
                onStart: (event) => {
                    document.documentElement.style.userSelect = 'none';
                },
                onEnd: (event) => {
                    const result = structuredClone(this.data);
                    result.splice(event.newIndex, 0, result.splice(event.oldIndex, 1)[0]);
                    this.data = result;
                    document.documentElement.style.userSelect = 'auto';
                }
            });
        }
    }

    async changeSequence(active: boolean) {
        this.SORT = { code: 'sequence', dir: 'asc' };
        this.setRPP();

        if (active) {
            this.editSequence = true;
            this.sortableContainer?.option('disabled', false);
            await this.getNews(true);
            this.initSortable();
        } else {
            this.editSequence = false;
            this.sortableContainer?.option('disabled', true);
            this.getNews();
        }
    }
    submitSequence() {
        this.loading = true;
        const idSequence: string[] = this.data.map((x) => x.id);
        this.defaultService.meldpuntNewsitemsChangeNewsitemsSequence({ newsitemsIds: idSequence }).subscribe({
            next: (next) => {
                this.loading = false;
                this.toastr.success('Volgorde werd succesvol aangepast', 'Volgorde aangepast');
                this.changeSequence(false);
            },
            error: (error) => {
                this.toastr.error('Er liep iets mis bij het aanpassen van de volgorde', 'Volgorde aanpassen mislukt');
                this.loading = false;
            }
        });
    }
}
