import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { ngfModule, ngf } from 'angular-file';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiInterceptor } from './utils/interceptors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldToggleComponent } from './components/fields/field-toggle/field-toggle.component';
import { FieldTextareaComponent } from './components/fields/field-textarea/field-textarea.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { ApiModule, Configuration } from './utils/api';
import { AvatarComponent } from './components/common/avatar/avatar.component';
import { PopoutPanelComponent } from './components/layout/popout-panel/popout-panel.component';
import { FieldSwitchComponent } from './components/fields/field-switch/field-switch.component';
import { FilterButtonComponent } from './components/common/filter-button/filter-button.component';
import { TableComponent } from './components/common/table/table.component';
import { PopoverComponent } from './components/common/popover/popover.component';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    hightlightPipe,
    callbackPipe,
    SafePipe,
    floatDisplayPipe,
    ArrayPipe,
    YesNoPipe,
    YesNoUnknownPipe
} from './utils/pipes';
import { FilterButtonGroupComponent } from './components/common/filter-button-group/filter-button-group.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { GlobalErrorHandler } from './utils/errorHandler';
import { ModalSidePanelComponent } from './components/layout/modal-side-panel/modal-side-panel.component';
import { SideNavigationComponent } from './components/common/side-navigation/side-navigation.component';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { ShimmerComponent } from './components/misc/shimmer/shimmer.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ModeSwitchComponent } from './components/misc/mode-switch/mode-switch.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { ConfirmDeleteComponent } from './components/misc/confirm-delete/confirm-delete.component';
import { FormComponent } from './components/common/paged-form/paged-form.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { DiscountDetailModalComponent } from './components/modals/discount-detail-modal/discount-detail-modal.component';
import { FieldRadiosComponent } from './components/fields/field-radios/field-radios.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { FieldTypeaheadSmallComponent } from './components/fields/field-typeahead-small/field-typeahead-small.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { LoginComponent } from './pages/login/login.component';
import { FieldCurrencyComponent } from './components/fields/field-currency/field-currency.component';
import { InlineSpinnerComponent } from './components/misc/inline-spinner/inline-spinner.component';
import { NewslettersComponent } from './pages/newsletters/newsletters.component';
import { SelectionsComponent } from './pages/selections/selections.component';
import { SelectionDetailModalComponent } from './components/modals/selection-detail-modal/selection-detail-modal.component';
import { NewsletterDetailModalComponent } from './components/modals/newsletter-detail-modal/newsletter-detail-modal.component';
import { EditorAddBlockComponent } from './components/editor/editor-add-block/editor-add-block.component';
import { EditorBlockTextComponent } from './components/editor/blocks/editor-block-text/editor-block-text.component';
import { EditorBlockTextRightComponent } from './components/editor/blocks/editor-block-text-right/editor-block-text-right.component';
import { EditorBlockTextLeftComponent } from './components/editor/blocks/editor-block-text-left/editor-block-text-left.component';
import { EditorBlockImageComponent } from './components/editor/blocks/editor-block-image/editor-block-image.component';
import { EditorBlockVideoComponent } from './components/editor/blocks/editor-block-video/editor-block-video.component';
import { EditorLinkComponent } from './components/editor/modals/editor-link/editor-link.component';
import { EditorCropComponent } from './components/editor/modals/editor-crop/editor-crop.component';
import { EditorVideoLinkComponent } from './components/editor/modals/editor-video-link/editor-video-link.component';
import { RecipientsModalComponent } from './components/modals/recipients-modal/recipients-modal.component';
import { RecipientsSentModalComponent } from './components/modals/recipients-sent-modal/recipients-sent-modal.component';
import { EditorImageLinkComponent } from './components/editor/modals/editor-image-link/editor-image-link.component';
import { ConfirmSendComponent } from './components/modals/confirm-send/confirm-send.component';
import { NavigationComponent } from './components/layout/navigation/navigation.component';
import { ProfileSectionComponent } from './components/layout/profile-section/profile-section.component';
import { ButtonComponent } from './components/common/button/button.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { ActiveSubstancesComponent } from './pages/active-substances/active-substances.component';
import { TipsComponent } from './pages/tips/tips.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PagesComponent } from './pages/pages/pages.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NewsComponent } from './pages/news/news.component';
import { SearchMultiselectComponent } from './components/search/search-multiselect/search-multiselect.component';
import { SearchRadiosComponent } from './components/search/search-radios/search-radios.component';
import { SearchInputComponent } from './components/search/search-input/search-input.component';
import { ActiveSubstanceDetailModalComponent } from './components/modals/active-substance-detail-modal/active-substance-detail-modal.component';
import { ArrShowMoreComponent } from './components/common/arr-show-more/arr-show-more.component';
import { FieldRichtextComponent } from './components/fields/field-richtext/field-richtext.component';
import { AddBrandModalComponent } from './components/modals/add-brand-modal/add-brand-modal.component';
import { ContactDetailModalComponent } from './components/modals/contact-detail-modal/contact-detail-modal.component';
import { TipDetailModalComponent } from './components/modals/tip-detail-modal/tip-detail-modal.component';
import { PageDetailModalComponent } from './components/modals/page-detail-modal/page-detail-modal.component';
import { AddSubpageModalComponent } from './components/modals/add-subpage-modal/add-subpage-modal.component';
import { NewsDetailModalComponent } from './components/modals/news-detail-modal/news-detail-modal.component';
import { QuestionDetailModalComponent } from './components/modals/question-detail-modal/question-detail-modal.component';
import { ToolkitComponent } from './pages/toolkit/toolkit.component';
import { ToolkitDetailModalComponent } from './components/modals/toolkit-detail-modal/toolkit-detail-modal.component';
import { FieldImageComponent } from './components/fields/field-image/field-image.component';
import { ImageCropperComponent } from './components/modals/image-cropper/image-cropper.component';
import { BannerModalComponent } from './components/modals/banner-modal/banner-modal.component';
import { BannersComponent } from './pages/banners/banners.component';
import { HotlineComponent } from './pages/meldpunt/hotline/hotline.component';
import { HotlineDashboardComponent } from './pages/meldpunt/hotline-dashboard/hotline-dashboard.component';
import { HotlineNewsComponent } from './pages/meldpunt/hotline-news/hotline-news.component';
import { HotlinePagesComponent } from './pages/meldpunt/hotline-pages/hotline-pages.component';
import { HotlineNotificationsComponent } from './pages/meldpunt/hotline-notifications/hotline-notifications.component';
import { SearchMultiselectIconComponent } from './components/search/search-multiselect-icon/search-multiselect-icon.component';
import { SearchAgeComponent } from './components/search/search-period/search-age.component';
import { NotificationDetailModalComponent } from './components/modals/hotlines/notification-detail-modal/notification-detail-modal.component';
import { HotlinesPageDetailModalComponent } from './components/modals/hotlines/hotlines-page-detail-modal/hotlines-page-detail-modal.component';
import { HotlineNewsDetailModalComponent } from './components/modals/hotlines/hotline-news-detail-modal/hotline-news-detail-modal.component';
import { PopupDetailModalComponent } from './components/modals/hotlines/popup-detail-modal/popup-detail-modal.component';
import { HistoryComponent } from './components/common/history/history.component';
import { HotlineMedicinesComponent } from './pages/meldpunt/hotline-medicines/hotline-medicines.component';
import { FieldUploadDropzoneComponent } from './components/fields/field-upload-dropzone/field-upload-dropzone.component';
import { ThemeImagesComponent } from './components/modals/theme-images/theme-images.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DiscountComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldToggleComponent,
        FieldTextareaComponent,
        AvatarComponent,
        PopoutPanelComponent,
        FieldSwitchComponent,
        FilterButtonComponent,
        TableComponent,
        PopoverComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        floatDisplayPipe,
        hightlightPipe,
        callbackPipe,
        SafePipe,
        ArrayPipe,
        YesNoPipe,
        YesNoUnknownPipe,
        customCurrencyPipe,
        percentagePipe,
        FilterButtonGroupComponent,
        EmptyStateComponent,
        ModalSidePanelComponent,
        SideNavigationComponent,
        FieldSelectComponent,
        ShimmerComponent,
        ModeSwitchComponent,
        PaginationComponent,
        ConfirmDeleteComponent,
        FormComponent,
        FieldHorizontalRadioComponent,
        DiscountDetailModalComponent,
        FieldRadiosComponent,
        FieldTypeaheadComponent,
        FieldTypeaheadSmallComponent,
        FieldDateComponent,
        LoginComponent,
        FieldCurrencyComponent,
        InlineSpinnerComponent,
        NewslettersComponent,
        SelectionsComponent,
        SelectionDetailModalComponent,
        NewsletterDetailModalComponent,
        EditorAddBlockComponent,
        EditorBlockTextComponent,
        EditorBlockTextRightComponent,
        EditorBlockTextLeftComponent,
        EditorBlockImageComponent,
        EditorBlockVideoComponent,
        EditorLinkComponent,
        EditorCropComponent,
        EditorVideoLinkComponent,
        RecipientsModalComponent,
        RecipientsSentModalComponent,
        EditorImageLinkComponent,
        ConfirmSendComponent,
        NavigationComponent,
        ProfileSectionComponent,
        ButtonComponent,
        DashboardComponent,
        LineChartComponent,
        ActiveSubstancesComponent,
        TipsComponent,
        FaqComponent,
        PagesComponent,
        ContactComponent,
        NewsComponent,
        SearchMultiselectComponent,
        SearchMultiselectIconComponent,
        SearchAgeComponent,
        SearchRadiosComponent,
        SearchInputComponent,
        ActiveSubstanceDetailModalComponent,
        ArrShowMoreComponent,
        FieldRichtextComponent,
        AddBrandModalComponent,
        ContactDetailModalComponent,
        TipDetailModalComponent,
        PageDetailModalComponent,
        AddSubpageModalComponent,
        NewsDetailModalComponent,
        QuestionDetailModalComponent,
        ToolkitComponent,
        ToolkitDetailModalComponent,
        FieldImageComponent,
        ImageCropperComponent,
        BannerModalComponent,
        BannersComponent,
        HotlineComponent,
        HotlineDashboardComponent,
        HotlineNewsComponent,
        HotlinePagesComponent,
        HotlineNotificationsComponent,
        NotificationDetailModalComponent,
        HotlinesPageDetailModalComponent,
        HotlineNewsDetailModalComponent,
        HotlineNewsDetailModalComponent,
        PopupDetailModalComponent,
        HistoryComponent,
        HotlineMedicinesComponent,
        FieldUploadDropzoneComponent,
        ThemeImagesComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        }),
        QuillModule.forRoot(),
        ngfModule,
        ImageCropperModule
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        formatTsPipe,
        provideHttpClient(withInterceptors([ApiInterceptor]))
    ]
})
export class AppModule {}
