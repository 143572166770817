import { Component, inject, Input, OnInit, output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { HelpersService } from 'src/app/services/helpers.service';
import { DefaultService, MeldpuntPopupPayload, MeldpuntPopupDetailKnop } from 'src/app/utils/api';

export type PopupDetail = {
    id?: string;
    actief: boolean;
    titel: string;
    inhoud: string;
    toonKnop?: boolean;
    knop?: MeldpuntPopupDetailKnop;
    createTS: string;
    createUsername: string;
    editTS: string;
    editUsername: string;
};
@Component({
    selector: 'app-popup-detail-modal',
    templateUrl: './popup-detail-modal.component.html',
    styleUrl: './popup-detail-modal.component.scss',
    standalone: false
})
export class PopupDetailModalComponent implements OnInit {
    activeModal = inject(NgbActiveModal);
    defaultService = inject(DefaultService);
    route = inject(ActivatedRoute);
    toastr = inject(ToastrService);
    helperService = inject(HelpersService);
    updateOverview = output();

    id?: string;
    mode?: string;

    loading: boolean = true;
    ready: boolean = false;
    changes: boolean = false;
    submitting: boolean = false;

    data: PopupDetail;
    FORM: PopupDetail;
    validation: any;

    yesNoOptions = [
        { title: 'Ja', value: 'yes' },
        { title: 'Nee', value: 'no' }
    ];

    async ngOnInit(): Promise<void> {
        this.mode = this.route.snapshot.queryParamMap.get('mode');
        if (this.mode === 'create') {
            this.createEmptyItem();
        } else {
            this.id = this.route.snapshot.queryParamMap.get('popupId');
            this.getPopup(this.id);
        }
    }

    async getPopup(id: string) {
        this.loading = true;
        await lastValueFrom(this.defaultService.meldpuntPopupsGetPopupDetail(id)).then((next) => {
            this.data = {
                ...next,
                knop: {
                    titel: next?.knop?.titel,
                    link: next?.knop?.link,
                    nieuwTabblad: next?.knop?.nieuwTabblad
                }
            };
        });
        this.resetForm();
    }

    createEmptyItem() {
        this.data = {
            id: undefined,
            titel: undefined,
            inhoud: undefined,
            actief: false,
            toonKnop: false,
            knop: {
                titel: undefined,
                link: undefined,
                nieuwTabblad: false
            },
            createTS: undefined,
            createUsername: undefined,
            editTS: undefined,
            editUsername: undefined
        };
        this.resetForm();
    }

    resetForm() {
        if (this.canExit()) this.FORM = structuredClone(this.data);
        this.changes = false;
        this.ready = true;
        this.loading = false;
    }

    formChange() {
        this.changes = true;
    }

    submit() {
        this.submitting = true;
        const payload: MeldpuntPopupPayload = {
            id: this.FORM?.id,
            actief: this.FORM?.actief,
            titel: this.FORM?.titel,
            inhoud: this.FORM?.inhoud,
            toonKnop: this.FORM?.toonKnop,
            knop: this.FORM?.toonKnop
                ? {
                      titel: this.FORM?.knop?.titel,
                      link: this.helperService.fillHttp(this.FORM?.knop?.link),
                      nieuwTabblad: this.FORM?.knop?.nieuwTabblad
                  }
                : undefined
        };

        this.defaultService.meldpuntPopupsCreateOrUpdatePopup(payload).subscribe({
            next: async (next) => {
                this.submitting = false;
                this.changes = false;
                if (this.id) {
                    this.toastr.success('Popup werd succesvol gewijzigd', 'Popup gewijzigd');
                } else {
                    this.toastr.success('Popup werd succesvol aangemaakt', 'Popup aangemaakt');
                }
                // this.activeModal.close();
                this.updateOverview.emit();
                this.validation = undefined;
                await this.getPopup(next.id);
                this.mode = 'view';
            },
            error: (error) => {
                this.validation = error.error.details;
                this.submitting = false;
            }
        });
    }

    dismiss() {
        if (this.canExit()) {
            this.activeModal.dismiss();
        }
    }

    canExit(): boolean {
        if (
            (!this.changes && !this.submitting) ||
            confirm('Er zijn niet-opgeslagen wijzigingen. Ben je zeker dat je wil annulerem?')
        ) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
