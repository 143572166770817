<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
    <div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="ready" class="d-flex detail-modal newsletter-detail-modal">

        <!-- side panel -->
        <app-modal-side-panel [open]="!isSent || true">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
                <div class="p-l pb-s panel-head">
                    <button (click)="ActiveModal.dismiss()" [class.disabled]="saving" class="mb-xl fontsize-xs"> <i class="im-icon im-icon-arrow-left mr-0" style="font-size: 18px;"></i> <span class="fontsize-s d-inline-block" style="margin-left: 11px;line-height: 21px;transform: translateY(-2px);">Terug naar overzicht</span></button>  
                    <div class="">
                        <div class="d-flex flex-column titles">
                            <span class="title">
                                {{uuid ? newsletter.name : 'Nieuwe nieuwsbrief'}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 scroll-y saving--pre" [class.saving]="loading">
                    <app-side-navigation
                        class="d-block w-100"
                        [pageARR]="pages"
                        [model]="activePage"
                        (modelChange)="pageChange($event)">
                    </app-side-navigation>
                </div>
            </div>
        </app-modal-side-panel>

        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y" [class.pb-5]="getActivePage().code !== 'preview'">
            <div class="">
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <div class="pr-l" style="overflow: hidden; white-space: nowrap;">
                        <h1 style="min-height: 36px;" class="d-flex align-items-center">
                            <!-- <i class="active-page-icon" *ngIf="!isSent && getActivePage()?.icon" class="im-icon im-icon-{{getActivePage().icon}}"></i> -->
                            <span><ng-container>{{isSent ? newsletter.name : getActivePage().name}}</ng-container></span>
                        </h1>

                        <div class="passive-gray fontsize-s fontweight-300" style="line-height: 18px;" *ngIf="newsletter.editTS && !isSent">Concept laatst opgeslagen op {{newsletter.editTS | formatTs: 'D MMMM YYYY'}} om {{newsletter.editTS | formatTs: 'HH:mm'}}</div>
                        <div class="passive-gray fontsize-s fontweight-300" style="line-height: 18px;" *ngIf="newsletter.sendTS && isSent">Nieuwsbrief verzonden op {{newsletter.sendTS | formatTs: 'D MMMM YYYY'}} om {{newsletter.sendTS | formatTs: 'HH:mm'}}</div>
                    </div>
                    <!-- <div class="h-100 d-flex align-items-center" *ngIf="isSent" style="white-space: nowrap;"> -->
                        <!-- close -->
                        <!-- <button class="button-link blue ml-m" (click)="ActiveModal.dismiss()"><span>Sluit</span></button> -->
                    <!-- </div> -->
                    <div class="h-100 d-flex align-items-center" *ngIf="!isSent" style="white-space: nowrap;">
                        <!-- SAVE DRAFT -->
                        <button *ngIf="!uuid || getActivePage().code == 'content'" class="button-link blue fontsize-m underline transition mr-xxs" (click)="!saving && changes && saveNewsletterContent(true)" [class.passive-gray]="saving || !changes" [class.disabled]="saving || !changes"><span>Bewaar concept</span></button>
                        <!-- cancel -->
                        <!-- <button *ngIf="false && (!uuid || getActivePage().code == 'content')" class="button-link blue cancel" (click)="canExit() && ActiveModal.dismiss()" [class.disabled]="saving"><span>Annuleer</span></button> -->
                        <!-- prev -->
                        <button *ngIf="!firstPageActive()" class="button tertiary ml-xs" (click)="prevPage()" [class.disabled]="saving">Vorige</button>
                        <!-- next -->
                        <!-- <button *ngIf="!lastPageActive() && getActivePage().code != 'content' && getActivePage().code != 'recipients'" class="button tertiary ml-xs" (click)="nextPage()" [class.disabled]="saving">Volgende</button> -->
                        <app-button *ngIf="!lastPageActive() && getActivePage().code != 'content' && getActivePage().code != 'recipients'" classes="primary-default ml-xs" icon="" text="Volgende" [disabled]="saving" [submitting]="false" (emitClick)="nextPage()"></app-button>
                        <!-- SAVE & CONTINUE -->
                        <app-button *ngIf="getActivePage().code == 'content'" classes="primary-default ml-xs" icon="check-round-filled" text="Bewaar en ga verder" [disabled]="saving || (!uuid && !changes)" [submitting]="submitting" (emitClick)="saveNewsletterContent()"></app-button>
                        <!-- <app-button *ngIf="getActivePage().code == 'recipients'" type="primary-default ml-xs" icon="check-round-filled" text="Bewaar en ga verder" [disabled]="saving || (!uuid && !changes)" [saving]="submitting" (emitClick)="saveSelections()"></app-button> -->
                        <!-- <button *ngIf="getActivePage().code == 'content'" class="button default-sm ml-xs" (click)="!saving && (uuid || changes) && saveNewsletterContent()" [class.disabled]="saving || (!uuid && !changes)">Bewaar en ga verder</button> -->
                        <!-- <button *ngIf="getActivePage().code == 'recipients'" class="button default-sm ml-xs" (click)="!saving && (uuid || changes) && saveSelections()" [class.disabled]="saving">Bewaar en ga verder</button> -->
                    </div>
                </div>
            
                <ng-container *ngIf="loading">
                    <div style="height: 100%;">
                        <app-spinner [centerVertically]="true"></app-spinner>
                    </div>
                </ng-container>

                <div *ngIf="!loading" class="detail-modal-body">
                    <!--------- content --------->
                    <div class="saving--pre newsletter-content" [class.saving]="saving" *ngIf="activePage == 'content'">
                        <div class="row display-row mw-1000-px">
                            <div class="col-5">
                                <!-- type -->
                                <div class="display-content">
                                    <app-field-select 
                                        [model]="formSTR.newsletterTypeId" 
                                        [options]="typeARR"
                                        (modelChange)="formSTR.newsletterTypeId = $event; formChange(); clearSelections();" 
                                        label="Type nieuwsbrief"
                                        [error]="validation.newsletterTypeId"
                                        placeholder="Selecteer...">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="col-7">
                                <!-- sender -->
                                <div class="display-content">
                                    <div class="display-label field-label">Afzender</div>
                                    <div class="display-value d-flex flex-row">
                                        {{getSender() || '-'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1000-px">
                            <!-- <div class="col-10"> -->
                            <div class="col-5">
                                <!-- selected selection -->
                                <div class="display-label field-label">Selectie ontvangers</div>
                                <div *ngIf="selectionsFormSTR.selections.length" class="selections-search-results">
                                    <div class="selection-add-remove selection-add-remove--remove" *ngFor="let selection of selectionsFormSTR.selections">
                                        <div class="name">
                                            <div class="title">
                                                <span [innerHTML]="selection.name"></span> <span class="amount" *ngIf="selection.amount || selection.amount === 0"> <span [class.saving]="amountOfRecipientsLoadingCalls" *ngIf="amountOfRecipients !== null && amountOfRecipients !== undefined" class="passive-gray fontsize-m" style="line-height: 1;"><button (click)="openRecipientsModal()" [class.disabled]="!amountOfRecipients" class="button-link d-inline-block ml-xxs">toon lijst ({{amountOfRecipients}})</button></span></span>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <div class="sub">
                                                    <span class="fontsize-s" style="margin-top: 0px;">
                                                        {{selection.newsletterType.title | display}}
                                                        <ng-container *ngIf="selection.newsletterType && selection.newsletterType.stampsSubtitle">
                                                            <div class="d-inline-block" [class.app-tooltip-wrap]="selection.newsletterType.tooltipSubtitle">
                                                                <span *ngFor="let stamp of selection.newsletterType.stampsSubtitle" class="stamp" [class]="stamp.classList">
                                                                    {{stamp.value}}
                                                                </span>
                                                                <div *ngIf="selection.newsletterType.tooltipSubtitle" class="app-tooltip app-tooltip-default-centered">{{selection.newsletterType.tooltipSubtitle}}</div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="selection.newsletterType && selection.newsletterType.afterSubtitle">
                                                            {{selection.newsletterType.afterSubtitle}}
                                                        </ng-container>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button (click)="removeSelection(selection)" class="button-link d-inline-block ml-auto">Wijzig</button>
                                    </div>
                                </div>
                                <!-- search NEW -->
                                <ng-container *ngIf="!selectionsFormSTR.selections.length">
                                    <app-field-typeahead 
                                        [model]="typeaheadModels.newsletterTypeId" 
                                        (modelChange)="selectTypeaheadItem($event, 'selection')" 
                                        [getOptionsFn]="getNewsletterTypeOptions"
                                        [editable]="false"
                                        [afterARR]="['newsletterType']"
                                        placeholder="Zoek een selectie...">
                                    </app-field-typeahead>
                                </ng-container>
                                <!-- search OLD -->
                                <ng-container *ngIf="!selectionsFormSTR.selections.length && false">
                                    <div class="position-relative">
                                        <app-field-text 
                                            [disabled]="!formSTR.newsletterTypeId"
                                            [model]="selectionSearchSTR.term" 
                                            (modelChange)="selectionSearchSTR.term = $event" 
                                            (enter)="searchSelections()" 
                                            typeClass="search"
                                            placeholder="Zoek een selectie...">
                                        </app-field-text>
                                        <button (click)="searchSelections()" *ngIf="formSTR.newsletterTypeId" class="button-link d-inline-block" style="position: absolute; top: 50%; right: 11px; transform: translateY(-50%); height: auto; padding: 5px;">Zoek</button>
                                    </div>
                                    <!-- search results -->
                                    <div *ngIf="formSTR.newsletterTypeId && selections && selections.length" class="selections-search-results saving--pre ml-xs mr-xs" [class.saving]="selectionsLoading">
                                        <div class="selection-add-remove" *ngFor="let selection of selections | callback: filterSelectionResults">
                                            <div class="name">
                                                <div class="title">
                                                    <span [innerHTML]="selection.name | highlight: selectionSearchSTR.term"></span> <span class="amount" *ngIf="selection.amount || selection.amount === 0"> ({{selection.amount}})</span>
                                                </div>
                                                <div class="sub">{{selection.newsletterType}} - {{selection.amountOfConditions}} voorwaarde{{selection.amountOfConditions != 1 ? 'n' : ''}}</div>
                                            </div>
                                            <button (click)="addSelection(selection)" class="button-link d-inline-block ml-auto">Selecteer</button>
                                        </div>
                                    </div>
                                    <!-- emptystate -->
                                    <div *ngIf="formSTR.newsletterTypeId && selections && !selections.length && !selectionsLoading" class="selections-search-emptystate mt-xs">
                                        <div>Er werden geen selecties gevonden voor '{{selectionSearchSTR.term || ''}}'.</div>
                                        <div><span>Probeer een andere zoekterm of </span> <button class="button-link" (click)="goToNewSelection()">maak nieuwe selectie aan</button>.</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row display-row mw-1000-px">
                            <div class="col-5">
                                <!-- template -->
                                <div class="display-content">
                                    <app-field-select 
                                        [model]="formSTR.newsletterTemplateId" 
                                        [options]="templateARR"
                                        (modelChange)="formSTR.newsletterTemplateId = $event; !$event && resetTemplate(); getTemplate($event, true); formChange();" 
                                        label="Template"
                                        [error]="validation.newsletterTemplateId"
                                        placeholder="Selecteer...">
                                    </app-field-select>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-12">
                                <!-- name -->
                                <div class="display-content" style="max-width: 675px;">
                                    <app-field-text 
                                        [model]="formSTR.name" 
                                        (modelChange)="formSTR.name = $event; formChange();" 
                                        [instant]="true"
                                        label="Onderwerp"
                                        [error]="validation.name"
                                        placeholder="">
                                    </app-field-text>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="template">
                            <div class="col-12">
                                <div class="display-label field-label mb-xs">Inhoud</div>
                                <div class="position-relative" style="margin-left: 10px;">
                                    <div class="editor-bg"></div>
                                    <div class="position-relative" style="z-index: 1">
                                        <!-- header -->
                                        <div class="d-none" [innerHtml]="template.mail_header" style="max-width: 655px;"></div>

                                        <!-- custom header -->
                                        <div style="max-width: 655px; min-height: 132px;">
                                            <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="background-color: #fff;">
                                                <tr>
                                                    <td></td>
                                                    <td width="100%" align="center" style="padding: 0;margin: 0;padding-top: 26px;padding-bottom: 40px;padding-left: 24px;padding-right: 24px;">
                                                        <table border="0" cellspacing="0" cellpadding="0" align="center" width="100%" style="padding:0;">
                                                            <tr>
                                                                <td width="1%" style="text-align: left;padding:0;margin:0;vertical-align:middle;padding-right: 10px;padding-bottom: 2px;">
                                                                    <a href="https://www.medicijngebruik.nl/"><img [src]="'/'+formSTR.newsletterContent[0].imagePath" height="64" style="vertical-align: middle"/></a>
                                                                </td>
                                                                <td width="60%" style="text-align: left; padding:0; margin:0; vertical-align:bottom;">
                                                                    <p style="margin: 0;padding: 0;font-size: 16px;line-height:16px;font-weight: 300;margin-bottom: 6px;color: #404040;font-family:sans-serif;" class="no-enter" contenteditable="true" (blur)="formSTR.newsletterContent[0].title = $event.target.innerText" [textContent]="formSTR.newsletterContent[0].title"></p>
                                                                    <p style="margin: 0;padding: 0;font-size: 16px;line-height:16px;font-weight: 300;color: #404040;font-family:sans-serif;" class="no-enter" contenteditable="true" (blur)="formSTR.newsletterContent[0].subtitle = $event.target.innerText" [textContent]="formSTR.newsletterContent[0].subtitle"></p>
                                                                </td>
                                                                <td width="60%" style="text-align: right; padding:0; margin:0; vertical-align:bottom;">
                                                                    <p style="padding: 0;margin: 0;font-weight: 600;font-size: 14px;line-height:15px;font-family:sans-serif;" class="no-enter editor-tertiary-color" contenteditable="true" (blur)="formSTR.newsletterContent[0].right = $event.target.innerText" [textContent]="formSTR.newsletterContent[0].right"></p>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>

                                        <!-- content -->
                                        <div class="editor quill-wrap">

                                            <!-- add block -->
                                            <!-- <div style="max-width: 655px;">
                                                <app-editor-add-block 
                                                    [model]="formSTR.newsletterContent" 
                                                    (add)="EditorService.addBlock(formSTR.newsletterContent, $event, 1); blockAdded($event, 1)"
                                                    [types]="editorTypeARR"
                                                ></app-editor-add-block>
                                            </div> -->

                                            
                                            <div id="sort-blocks">
                                                <!-- loop through blocks -->
                                                <div class="editor-block-drag" *ngFor="let block of formSTR.newsletterContent; index as $i">
                                                    
                                                    <div class="editor-block sortable-item" [class.active]="getActiveIndex() == $i" style="max-width: 655px;" *ngIf="block.type != 'newsletterHeader'">
                                                        <div class="editor-block-inner">
                                                            <div appClickOutside (clickOutside)="getActiveIndex() == $i && !blockModalOpen() && setActiveIndex(null)">
                                                                <!-- text -->
                                                                <app-editor-block-text
                                                                    class="d-block"
                                                                    *ngIf="block.type == 'text'"
                                                                    [active]="getActiveIndex() == $i"
                                                                    (setActive)="setActiveIndex($i)"
                                                                    (setInactive)="setActiveIndex(null)"
                                                                    (setActiveModal)="$event ? helper.activeModal = $i : helper.activeModal = null"
                                                                    [model]="formSTR.newsletterContent[$i].html"
                                                                    (modelChange)="setHTML($event, formSTR.newsletterContent[$i]); formChange()"
                                                                ></app-editor-block-text>
                                                                <!-- text right -->
                                                                <app-editor-block-text-right
                                                                    class="d-block"
                                                                    *ngIf="block.type == 'text-right'"
                                                                    [active]="getActiveIndex() == $i"
                                                                    (setActive)="setActiveIndex($i)"
                                                                    (setInactive)="setActiveIndex(null)"
                                                                    (setActiveModal)="$event ? helper.activeModal = $i : helper.activeModal = null"
                                                                    [model]="formSTR.newsletterContent[$i].html"
                                                                    (modelChange)="setHTML($event, formSTR.newsletterContent[$i]); formChange()"
                                                                    [url]="formSTR.newsletterContent[$i].url"
                                                                    (urlChange)="formSTR.newsletterContent[$i].url = $event; formChange()"
                                                                    [fileId]="formSTR.newsletterContent[$i].fileId"
                                                                    [uploadQueue]="uploadQueue"
                                                                    [files]="files"
                                                                    (addFile)="addFileToBlock($event, $i); formChange()"
                                                                    (removeFile)="removeFileFromBlock($event, $i); formChange()"
                                                                ></app-editor-block-text-right>
                                                                <!-- text right -->
                                                                <app-editor-block-text-left
                                                                    class="d-block"
                                                                    *ngIf="block.type == 'text-left'"
                                                                    [active]="getActiveIndex() == $i"
                                                                    (setActive)="setActiveIndex($i)"
                                                                    (setInactive)="setActiveIndex(null)"
                                                                    (setActiveModal)="$event ? helper.activeModal = $i : helper.activeModal = null"
                                                                    [model]="formSTR.newsletterContent[$i].html"
                                                                    (modelChange)="setHTML($event, formSTR.newsletterContent[$i]); formChange()"
                                                                    [url]="formSTR.newsletterContent[$i].url"
                                                                    (urlChange)="formSTR.newsletterContent[$i].url = $event; formChange()"
                                                                    [fileId]="formSTR.newsletterContent[$i].fileId"
                                                                    [uploadQueue]="uploadQueue"
                                                                    [files]="files"
                                                                    (addFile)="addFileToBlock($event, $i); formChange()"
                                                                    (removeFile)="removeFileFromBlock($event, $i); formChange()"
                                                                ></app-editor-block-text-left>
                                                                <!-- image -->
                                                                <app-editor-block-image
                                                                    class="d-block"
                                                                    *ngIf="block.type == 'image'"
                                                                    [active]="getActiveIndex() == $i"
                                                                    (setActive)="setActiveIndex($i)"
                                                                    (setInactive)="setActiveIndex(null)"
                                                                    (setActiveModal)="$event ? helper.activeModal = $i : helper.activeModal = null"
                                                                    [model]="formSTR.newsletterContent[$i].html"
                                                                    (modelChange)="setHTML($event, formSTR.newsletterContent[$i]); formChange()"
                                                                    [url]="formSTR.newsletterContent[$i].url"
                                                                    (urlChange)="formSTR.newsletterContent[$i].url = $event; formChange()"
                                                                    [fileId]="formSTR.newsletterContent[$i].fileId"
                                                                    [uploadQueue]="uploadQueue"
                                                                    [files]="files"
                                                                    (addFile)="addFileToBlock($event, $i); formChange()"
                                                                    (removeFile)="removeFileFromBlock($event, $i); formChange()"
                                                                ></app-editor-block-image>
                                                                <!-- video -->
                                                                <app-editor-block-video
                                                                    class="d-block"
                                                                    *ngIf="block.type == 'video'"
                                                                    [active]="getActiveIndex() == $i"
                                                                    (setActive)="setActiveIndex($i)"
                                                                    (setInactive)="setActiveIndex(null)"
                                                                    (setActiveModal)="$event ? helper.activeModal = $i : helper.activeModal = null"
                                                                    [model]="formSTR.newsletterContent[$i].html"
                                                                    (modelChange)="setHTML($event, formSTR.newsletterContent[$i]); formChange()"
                                                                    [url]="formSTR.newsletterContent[$i].url"
                                                                    (urlChange)="formSTR.newsletterContent[$i].url = $event; formChange()"
                                                                    [fileId]="formSTR.newsletterContent[$i].fileId"
                                                                    [uploadQueue]="uploadQueue"
                                                                    [files]="files"
                                                                    (addFile)="addFileToBlock($event, $i); formChange()"
                                                                    (removeFile)="removeFileFromBlock($event, $i); formChange()"
                                                                ></app-editor-block-video>
                                                            </div>

                                                        </div>

                                                        <!-- block after -->
                                                        <div class="editor-block-after-section" [class.active]="getActiveIndex() == $i">
                                                            <i class="im-icon im-icon-bars-burger sortable-handle" *ngIf="getActiveIndex() != $i"></i>
                                                            <span class="delete-wrap position-relative" appClickOutside (clickOutside)="popoverHelper = 'delete_' + $i && popoverHelper = null" (click)="$event.stopPropagation() || popoverHelper = 'delete_' + $i">
                                                                <i class="im-icon im-icon-trash hide-dragging" (click)="$event.stopPropagation() || setActiveIndex($i) || popoverHelper = 'delete_' + $i"></i>
                                                                <ng-container *ngIf="getActiveIndex() == $i">
                                                                    <span>Sectie verwijderen</span>
                                                                    <app-confirm-delete (confirm)="deleteSection($i)" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_' + $i" positionClass="center"></app-confirm-delete>
                                                                </ng-container>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <!-- add block -->
                                                    <div style="max-width: 655px;" class="hide-dragging">
                                                        <app-editor-add-block 
                                                            [model]="formSTR.newsletterContent" 
                                                            (add)="EditorService.addBlock(formSTR.newsletterContent, $event, $i + 1); blockAdded($event, $i + 1)"
                                                            [types]="editorTypeARR"
                                                        ></app-editor-add-block>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- footer -->
                                        <div [innerHtml]="template.mail_footer" style="max-width: 655px;"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <!--------- recipients OLD --------->
                    <div *ngIf="activePage == 'recipients' && false">
                        
                        <div class="row display-row mw-700-px">
                            <div class="col-7">
                                <!-- type -->
                                <div class="display-content">
                                    <div class="display-label field-label">Type nieuwsbrief</div>
                                    <div class="display-value d-flex flex-row">
                                        {{ResourceService.getNewsletterTypeById(newsletter.newsletterTypeId).name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <!-- sender -->
                                <div class="display-content">
                                    <div class="display-label field-label">Afzender</div>
                                    <div class="display-value d-flex flex-row">
                                        {{getSender() || '-'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-700-px">
                            <div class="col-7">
                                <!-- template -->
                                <div class="display-content">
                                    <div class="display-label field-label">Template</div>
                                    <div class="display-value d-flex flex-row">
                                        {{ResourceService.getNewsletterTemplateById(newsletter.newsletterTemplateId).name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-700-px">
                            <div class="col-12">
                                <!-- name -->
                                <div class="display-content">
                                    <div class="display-label field-label">Onderwerp</div>
                                    <div class="display-value d-flex flex-row">
                                        {{newsletter.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-700-px mt-m">
                            <div class="col-12">
                                <!-- selected selection -->
                                <div class="display-label field-label">Selectie ontvangers</div>
                                <div *ngIf="selectionsFormSTR.selections.length" class="selections-search-results">
                                    <div class="selection-add-remove selection-add-remove--remove" *ngFor="let selection of selectionsFormSTR.selections">
                                        <div class="name">
                                            <div class="title">
                                                <span [innerHTML]="selection.name"></span> <span class="amount" *ngIf="selection.amount || selection.amount === 0"> <span [class.saving]="amountOfRecipientsLoadingCalls" *ngIf="amountOfRecipients !== null && amountOfRecipients !== undefined" class="passive-gray fontsize-m" style="line-height: 16px;"><button (click)="openRecipientsModal()" class="button-link d-inline-block ml-xxs">toon lijst ({{amountOfRecipients}})</button></span></span>
                                            </div>
                                            <div class="sub">{{selection.newsletterType}} - {{selection.amountOfConditions}} voorwaarde{{selection.amountOfConditions != 1 ? 'n' : ''}}</div>
                                        </div>
                                        <button (click)="removeSelection(selection)" class="button-link d-inline-block ml-auto">Wijzig</button>
                                    </div>
                                </div>
                                <!-- search -->
                                <ng-container *ngIf="!selectionsFormSTR.selections.length">
                                    <div class="position-relative">
                                        <app-field-text 
                                            [model]="selectionSearchSTR.term" 
                                            (modelChange)="selectionSearchSTR.term = $event" 
                                            (enter)="searchSelections()" 
                                            typeClass="search"
                                            placeholder="Zoek een selectie...">
                                        </app-field-text>
                                        <button (click)="searchSelections()" class="button-link d-inline-block" style="position: absolute; top: 50%; right: 11px; transform: translateY(-50%); height: auto; padding: 5px;">Zoek</button>
                                    </div>
                                    <!-- search results -->
                                    <div *ngIf="selections && selections.length" class="selections-search-results saving--pre ml-xs mr-xs" [class.saving]="selectionsLoading">
                                        <div class="selection-add-remove" *ngFor="let selection of selections | callback: filterSelectionResults">
                                            <div class="name">
                                                <div class="title">
                                                    <span [innerHTML]="selection.name | highlight: selectionSearchSTR.term"></span> <span class="amount" *ngIf="selection.amount || selection.amount === 0"> ({{selection.amount}})</span>
                                                </div>
                                                <div class="sub">{{selection.newsletterType}} - {{selection.amountOfConditions}} voorwaarde{{selection.amountOfConditions != 1 ? 'n' : ''}}</div>
                                            </div>
                                            <button (click)="addSelection(selection)" class="button-link d-inline-block ml-auto">Selecteer</button>
                                        </div>
                                    </div>
                                    <!-- emptystate -->
                                    <div *ngIf="selections && !selections.length && !selectionsLoading" class="selections-search-emptystate mt-xs">
                                        <div>Er werden geen selecties gevonden voor '{{selectionSearchSTR.term || ''}}'.</div>
                                        <div><span>Probeer een andere zoekterm of </span> <button class="button-link" (click)="goToNewSelection()">maak nieuwe selectie aan</button>.</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                    </div>

                    <!--------- preview / sent --------->
                    <div *ngIf="activePage == 'preview'">
                        
                        <div class="d-flex preview-wrap">
                            <!-- preview -->
                            <div class="preview sans-serif" *ngIf="!loading" [innerHtml]="preview">
                                <!-- HTML -->
                            </div>
                            <!-- send test -->
                            <div class="send-test saving--pre flex-grow-1 mw-1200-px" [class.saving]="loading" *ngIf="!isSent">
                                <h3 class="mt-s mb-xs">Test versturen</h3>
                                <div class="mb-s">
                                    <app-field-text 
                                        [model]="testRecipients" 
                                        (modelChange)="testRecipients = $event" 
                                        label="Ontvangers"
                                        [instant]="true"
                                        placeholder="E-mailadressen">
                                    </app-field-text>
                                    <p class="passive-gray fontsize-s mt-xxs" style="word-break: break-all;">Ontvangers scheiden a.d.h.v. komma (bvb.: info&#64;medicijngebruik.nl,test&#64;medicijngebruik.nl...)</p>
                                </div>
                                <div class="d-flex">
                                    <!-- <button (click)="canSendTest() && sendTest()" [class.disabled]="!canSendTest()" class="button default"><i class="im-icon im-icon-send"></i>Verzend test</button> -->
                                    <app-button classes="primary-default" icon="send" iconSize="14px" text="Verzend test" [disabled]="!canSendTest()" [submitting]="submitting" (emitClick)="sendTest()"></app-button>
                                </div>
                            </div>
                            <!-- sent -->
                            <div class="sent-check saving--pre flex-grow-1 mw-1200-px" *ngIf="isSent && !loading">
                                <!-- <h3 class="mt-s mb-xs">Test versturen</h3> -->
                                <div class="newsletter-checklist saving--pre" [class.saving]="saving">
                                    <!-- sent info -->
                                    <div class="newsletter-checklist-check" [class.valid]="isSent">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Verzonden door {{newsletter.sentBy | display}}</div>
                                            <div class="sub">Op {{newsletter.sendTS | formatTs: 'D MMMM YYYY'}} om {{newsletter.sendTS | formatTs: 'HH:mm'}}</div>
                                        </div>
                                    </div>
                                    <!-- subject -->
                                    <div class="newsletter-checklist-check" [class.valid]="newsletter.name">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Onderwerp</div>
                                            <div class="sub"><ng-container *ngIf="newsletter.name">"{{newsletter.name}}"</ng-container><ng-container *ngIf="!newsletter.name">-</ng-container></div>
                                        </div>
                                    </div>
                                    <!-- sender -->
                                    <div class="newsletter-checklist-check" [class.valid]="newsletter.sender">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Afzender</div>
                                            <div class="sub">{{newsletter.sender | display}}</div>
                                        </div>
                                    </div>
                                    <!-- content -->
                                    <div class="newsletter-checklist-check" [class.valid]="newsletter.newsletterTemplate">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Inhoud</div>
                                            <div class="sub"><ng-container *ngIf="newsletter.newsletterTemplate">E-mail op basis van template "{{newsletter.newsletterTemplate}}"</ng-container><ng-container *ngIf="!newsletter.newsletterTemplate">-</ng-container></div>
                                        </div>
                                    </div>
                                    <!-- recipients -->
                                    <div class="newsletter-checklist-check" [class.valid]="newsletter?.recipients?.length">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Ontvangers</div>
                                            <div class="sub"><ng-container *ngIf="newsletter?.recipients?.length">Nieuwsbrief verzonden naar <button (click)="openRecipientsSentModal()" class="button-link d-inline-block underline">{{newsletter?.recipients?.length}} ontvanger{{newsletter?.recipients?.length == 1 ? '' : 's'}}</button></ng-container></div>
                                        </div>
                                    </div>
                                    <!-- test -->
                                    <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('test')">
                                        <div>
                                            <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                        </div>
                                        <div class="info flex-grow-1">
                                            <div class="title">Testmail verzonden</div>
                                            <div class="sub"><ng-container *ngIf="!checklistValidByCode('test')">Je hebt nog geen testmail verzonden.</ng-container><ng-container *ngIf="checklistValidByCode('test')">Je hebt een testmail verzonden.</ng-container></div>
                                        </div>
                                    </div>
                                    <!-- stopped status -->
                                    <div class="stopped-status-wrap" *ngIf="newsletter.sendStatus == 'stopped'">
                                        <button (click)="sendStopped()" [class.disabled]="saving" class="button default"><i class="im-icon im-icon-send"></i>Verzenden hervatten</button>
                                    </div>
                                </div>
                                <div class="links-wrap" *ngIf="newsletter.links?.length">
                                    <div class="label">Link clicks</div>
                                    <ng-container *ngFor="let link of newsletter.links">
                                        <div class="link-wrap" [attr.data-ref]="link.ref">
                                            <a class="ellipsis" (mouseover)="highlightLink(link.ref)" (mouseout)="unhighlightLink(link.ref)" [href]="link.href">{{link.href}}</a>
                                            <span>{{link.clicks || 0}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!--------- send --------->
                    <div *ngIf="activePage == 'send'">
                        
                        <div class="d-flex flex-column send-wrap saving--pre mw-550-px" [class.saving]="loading">
                            <h3>Klaar om te verzenden?</h3>
                            <p class="passive-gray fontsize-s mt-xxxs mb-xs">Kijk hieronder in de checklist na of je niets vergeten bent.</p>
                            <div class="newsletter-checklist saving--pre" [class.saving]="saving">
                                <!-- subject -->
                                <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('subject')">
                                    <div>
                                        <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                    </div>
                                    <div class="info flex-grow-1">
                                        <div class="title">Onderwerp</div>
                                        <div class="sub"><ng-container *ngIf="newsletter.name">"{{newsletter.name}}"</ng-container><ng-container *ngIf="!newsletter.name">-</ng-container></div>
                                    </div>
                                    <div>
                                        <div class="edit-wrap" (click)="pageChange('content')"><i class="im-icon im-icon-pencil"></i></div>
                                    </div>
                                </div>
                                <!-- sender -->
                                <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('sender')">
                                    <div>
                                        <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                    </div>
                                    <div class="info flex-grow-1">
                                        <div class="title">Afzender</div>
                                        <div class="sub">{{getSender() | display}}</div>
                                    </div>
                                    <div>
                                        <!-- <div class="edit-wrap" (click)="pageChange('content')"><i class="im-icon im-icon-pencil"></i></div> -->
                                    </div>
                                </div>
                                <!-- content -->
                                <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('content')">
                                    <div>
                                        <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                    </div>
                                    <div class="info flex-grow-1">
                                        <div class="title">Inhoud</div>
                                        <div class="sub"><ng-container *ngIf="template.name">Je stuurt een e-mail op basis van template "{{template.name}}"</ng-container><ng-container *ngIf="!template.name">-</ng-container></div>
                                    </div>
                                    <div>
                                        <div class="edit-wrap" (click)="pageChange('content')"><i class="im-icon im-icon-pencil"></i></div>
                                    </div>
                                </div>
                                <!-- recipients -->
                                <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('recipients')">
                                    <div>
                                        <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                    </div>
                                    <div class="info flex-grow-1">
                                        <div class="title">Ontvangers</div>
                                        <div *ngIf="!selectionsFormSTR.selections.length" class="sub">Je hebt {{selectionsFormSTR.selections.length || 'nog geen'}} lijst{{selectionsFormSTR.selections.length > 1 ? 'en' : ''}} geselecteerd. <ng-container *ngIf="amountOfRecipients"><button (click)="openRecipientsModal()" class="button-link d-inline-block underline">({{amountOfRecipients}} ontvanger{{amountOfRecipients == 1 ? '' : 's'}})</button></ng-container></div>
                                        <div *ngIf="selectionsFormSTR.selections.length" class="sub">{{selectionsFormSTR.selections[0].name}} <ng-container *ngIf="amountOfRecipients || amountOfRecipients === 0"><button [class.disabled]="!amountOfRecipients" (click)="openRecipientsModal()" class="button-link d-inline-block underline">({{amountOfRecipients}} ontvanger{{amountOfRecipients == 1 ? '' : 's'}})</button></ng-container></div>
                                    </div>
                                    <div>
                                        <div class="edit-wrap" (click)="pageChange('content')"><i class="im-icon im-icon-pencil"></i></div>
                                    </div>
                                </div>
                                <!-- test -->
                                <div class="newsletter-checklist-check" [class.valid]="checklistValidByCode('test')">
                                    <div>
                                        <div class="check-wrap"><i class="im-icon im-icon-check"></i></div>
                                    </div>
                                    <div class="info flex-grow-1">
                                        <div class="title">Testmail verzonden</div>
                                        <div class="sub"><ng-container *ngIf="!checklistValidByCode('test')">Je hebt nog geen testmail verzonden.</ng-container><ng-container *ngIf="checklistValidByCode('test')">Je hebt een testmail verzonden.</ng-container></div>
                                    </div>
                                    <div>
                                        <div class="edit-wrap" (click)="pageChange('preview')"><i class="im-icon im-icon-pencil"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-s mb-m">
                                <app-button classes="primary-default" icon="send" iconSize="14px" text="Verstuur nu" [disabled]="saving || !checklistValid()" [submitting]="submitting" (emitClick)="openConfirmSendNewsletter()"></app-button>
                            </div>

                        </div>

                    </div>

                    <!--------- statistics --------->
                    <div *ngIf="activePage == 'stats'">
                        
                        <div class="stats-wrap">

                            <iframe class="stats-frame" [src]="'/api/newsletter/stats/' + uuid | safe : 'resourceUrl'" (load)="iframeLoaded()" id="statsFrame"></iframe>

                            <div class="export-wrap" *ngIf="helper.statsFrameLoaded">
                                <h2>Exporteer rapport</h2>
                                <div class="buttons-wrap">
                                    <app-button [linkHref]="'/api/newsletter/stats/' + uuid + '/pdf?mode=' + helper.statsMode" classes="passive-blue mr-s" icon="doc-pdf" text="PDF exporteren" [disabled]="false" [submitting]="false" (emitClick)="false"></app-button>
                                    <!-- <app-button [linkHref]="'/api/newsletter/stats/' + uuid + '/pdf'" classes="passive-blue" icon="doc-excel" text="Excel exporteren" [disabled]="false" [submitting]="false" (emitClick)="false"></app-button> -->
                                </div>
                            </div>

                        </div>

                    </div>

                </div>



            </div>
        </div>
    </div>
</div>