import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import Sortable from 'sortablejs';
import { ToolkitDetailModalComponent } from 'src/app/components/modals/toolkit-detail-modal/toolkit-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { ToolkitService } from 'src/app/services/toolkit.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-toolkit',
    templateUrl: './toolkit.component.html',
    styleUrls: ['./toolkit.component.scss'],
    standalone: false
})
export class ToolkitComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<void>();
    popoverHelper: any = null;
    panelData: any = null;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};

    tableHeads: any = [
        { name: 'Titel', code: 'title', sortable: true, width: '70%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '7%' },
        { name: 'Gepubliceerd', code: 'isPublished', sortable: true, width: '2%' },
        { name: 'Gewijzigd op', code: 'updateTS', sortable: true, width: '7%' },
        { name: 'Gewijzigd door', code: 'updateBy', sortable: true, width: '7%' },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'sequence', dir: 'asc' };

    toolkits: any[] = null;
    startRow: number = 0;
    RPP: number = 999;
    totalRows: number = null;
    tableLoading: boolean = false;
    saving: boolean = false;
    ready: boolean = false;

    changeSequence: boolean;
    sortableContainer: Sortable;

    constructor(
        private ModalService: NgbModal,
        private ToolkitsService: ToolkitService,
        public HelpersService: HelpersService,
        public route: ActivatedRoute,
        public router: Router,
        private SearchService: SearchService,
        public ConfigService: ConfigService,
        private DefaultService: DefaultService
    ) {}

    clickChangeSequence() {
        this.initSearchSTR();
        this.SEARCHED = {};
        this.changeSequence = true;
        this.tableLoading = true;
        setTimeout(() => {
            this.getToolkits(true);
        }, 600);
    }

    ngOnInit(): void {
        this.initSearchSTR();
        const toolkitId = this.HelpersService.getParam('toolkitId');
        if (toolkitId) this.openCreateUpdateModal(toolkitId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));
        this.getToolkits();
    }

    getToolkits(change_sequence?: boolean) {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.toolkitsGetToolkits(
                change_sequence ? 0 : searchSTR.startRow,
                change_sequence ? null : searchSTR.rRP,
                change_sequence ? null : searchSTR.term,
                change_sequence ? ['sequence asc'] : searchSTR.orderBy
            )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.toolkits = next.data.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    this.totalRows = next.rows;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    if (this.changeSequence) {
                        this.initSortable();
                    }
                });
        });
    }

    getFormattedItem(item) {
        const actions = [
            {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            },
            {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            }
        ];
        let published = item.isPublished > 1 ? 'Niet gepubliceerd' : 'Gepubliceerd';
        let titleClassName = 'text-color';
        return {
            ...item,
            title: {
                type: 'default',
                classList: titleClassName,
                value: item.title
            },
            actions: {
                type: 'actions',
                actions: actions
            },
            updateTS: {
                type: 'ts',
                ts: item.updateTS,
                format: 'DD-MM-YYYY'
            },
            publishDate: {
                type: 'ts',
                ts: item.publishDate,
                format: 'DD-MM-YYYY'
            },
            createTS: {
                type: 'ts',
                ts: item.createTS,
                format: 'DD-MM-YYYY'
            },
            isPublished: { type: 'checkmark', checked: item.isPublished === 1 ? true : false }
        };
    }

    initSortable() {
        setTimeout(() => {
            const el: HTMLElement = document.querySelector('#sortable');
            this.sortableContainer?.destroy();
            if (el) {
                let that = this;
                this.sortableContainer = new Sortable(el, {
                    animation: 150,
                    dragClass: 'sortable-dragging',
                    ghostClass: 'sortable-ghost',
                    forceFallback: true,
                    handle: '.sortable-handle',
                    direction: 'vertical',
                    disabled: !that.changeSequence,
                    onEnd: (event) => {
                        const result = structuredClone(this.toolkits);
                        result.splice(event.newIndex, 0, result.splice(event.oldIndex, 1)[0]);
                        this.toolkits = result;
                    }
                });
            }
        }, 1);
    }

    clickUpdateSequence() {
        this.changeSequence = true;
        this.sortableContainer?.option('disabled', false);
    }

    cancelUpdateSequence() {
        this.changeSequence = false;
        this.sortableContainer?.option('disabled', true);
        this.tableLoading = true;
        setTimeout(() => {
            this.getToolkits();
        }, 200);
    }

    submitSequence() {
        this.saving = true;
        const FORM: number[] = this.toolkits.map((x) => x.id);
        this.DefaultService.toolkitsSetSequence({ articles: FORM }).subscribe((next) => {
            this.saving = false;
            this.tableLoading = false;
            this.cancelUpdateSequence();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(ToolkitDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('toolkitId', id);
            //add pathparam to url
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        } else this.HelpersService.addParam('toolkitId', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getToolkits();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('toolkitId');
            });
        // on create
        modalRef.componentInstance.created.subscribe((toolkit: any) => {
            this.HelpersService.addParam('toolkitId', toolkit.id);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((toolkit: any) => {
            this.getToolkits();
        });
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getToolkits();
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getToolkits();
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getDefaultSearch() {
        return {
            toolkits: []
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    setSort(code: string, dir: string) {
        this.tableLoading = true;
        this.tableSort = { code: code, dir: dir };
        this.getToolkits();
    }

    tableClick(item: any, head: string) {
        if (this.changeSequence) {
            return;
        }
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteToolkit(item.id);
                break;
        }
    }

    deleteToolkit(id) {
        this.dismissPopover();
        this.deSelectRows();
        this.ToolkitsService.deleteToolkit(id).subscribe((next) => {
            this.getToolkits();
        });
    }

    selectRow(item: any) {
        this.toolkits = this.toolkits.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getToolkits();
    };

    deSelectRows() {
        if (this.toolkits) {
            this.toolkits = this.toolkits.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    initSearchSTR(): void {
        this.searchSTR = {};
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
