import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    standalone: false
})
export class SearchInputComponent implements OnInit, AfterViewInit {
    @Input() model: any = null;
    @Input() placeholder: string = '';
    @Input() focus: boolean = false;
    @Input() loading: boolean = false;
    @Input() classes: string = '';
    @Input() error?: string;
    @Input() maxWidth?: string;
    @Input() disabled?: boolean;

    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    @Output() clear = new EventEmitter();
    @Output() hasBlur = new EventEmitter();
    @Output() hasFocus = new EventEmitter();

    @ViewChild('myinput') myInputField: ElementRef;
    ngAfterViewInit() {
        if (this.focus) {
            this.myInputField.nativeElement.focus();
        }
    }

    constructor() {}

    ngOnInit(): void {}
}
