import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Quill from 'quill';
import sanitizeHtml from 'sanitize-html';
import { EditorService } from 'src/app/services/editor.service';
@Component({
    selector: 'app-field-richtext',
    templateUrl: './field-richtext.component.html',
    styleUrls: ['./field-richtext.component.scss'],
    standalone: false
})
export class FieldRichtextComponent implements OnInit {
    @Input() label?: string = '';
    @Input() afterString?: string;
    @Input() error?: string;
    @Input() placeholder?: string = '';
    @Input() model: any;
    @Input() readOnly?: boolean;
    @Input() expanded?: boolean = false;
    @Input() meldpuntEditor?: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() setActiveModal = new EventEmitter();
    @Output() enter = new EventEmitter();
    @Output() focused = new EventEmitter();
    @Output() blurred = new EventEmitter();
    @Output() clicked = new EventEmitter();
    modules;
    editor: Quill = null;

    isFocused: boolean = false;

    constructor(private EditorService: EditorService) {}

    ngOnInit(): void {
        this.EditorService.init();
        if (this.readOnly) {
            this.modules = { toolbar: false };
        } else {
            this.modules = this.EditorService.getQuillModules();
        }
        this.model = sanitizeHtml(this.model, {
            allowedTags: ['p', 'em', 'strong', 'u', 'ol', 'li', 'ul', 'br', 'h1', 'h2', 'h3', 'a', 'br', 'blockquote'],
            allowedClasses: {
                '*': ['ql-align-center', 'ql-align-right', 'ql-align-justify', 'format-normal-link']
            }
        });
    }

    editorCreated($event) {
        this.editor = $event;
    }

    onFocus(event) {
        this.isFocused = true;
        this.focused.emit(true);
    }
    onBlur(event) {
        this.isFocused = false;
        this.blurred.emit(true);
    }
    onChange($event) {
        this.modelChange.emit($event.html || '');
    }

    openLinkModal() {
        this.EditorService.openLinkModal(this.editor, this.setActiveModal, ['normal_link', 'email_link']);
    }
}
