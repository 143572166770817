import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { TipsService } from 'src/app/services/tips.service';

@Component({
    selector: 'app-tip-detail-modal',
    templateUrl: './tip-detail-modal.component.html',
    styleUrls: ['./tip-detail-modal.component.scss'],
    standalone: false
})
export class TipDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    tip: any = {};
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = { homePage: false };

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(private ModalService: NgbModal, public ActiveModal: NgbActiveModal, public tipService: TipsService) {}

    ngOnInit(): void {
        // edit or create
        if (this.id && this.id !== 'new') {
            this.getTip();
        } else {
            this.tip = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }

    getTip() {
        this.loading = true;
        this.tipService
            .getTip(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.tip = { ...res, homePage: res.homePage || false };
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.ready = true;
                this.loading = false;
                this.resetForm();
            });
    }

    getPublishedText() {
        if (this.tip.isPublished === 1) {
            return 'Ja';
        }
        if (this.tip.isPublished === 2) {
            return 'Nee';
        }
    }

    createUpdateTip() {
        const FORM = this.formSTR;
        this.submitting = true;
        if (FORM.isPublished === 1 && !FORM.publishDate) {
            FORM.publishDate = new Date();
        }
        if (FORM.isPublished === 2) {
            FORM.publishDate = null;
        }
        this.saving = true;
        this.tipService
            .updateInsertTip(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.submitting = false;
                    this.saving = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    this.tip.id ? this.updated.emit(this.tip) : this.created.emit(this.tip);
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.tipService
            .deleteTip(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.tip);
                this.changes = false;
                this.ActiveModal.dismiss();
            });
    }

    formChange() {
        this.changes = true;
    }

    getHomePageText() {
        return this.tip.homePage ? 'Ja' : 'Nee';
    }

    resetForm() {
        this.formSTR = { ...this.tip };
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
