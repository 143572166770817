import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ConfigService } from './services/config.service';
import { ResourceService } from './services/resource.service';
import { Config } from './models/common';
import { AuthenticationService } from './services/authentication.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    fetchedInitialRouteData: boolean = false;
    isPublic: boolean;
    config: Config;
    darkMode: boolean;

    constructor(
        public ConfigService: ConfigService,
        private TitleService: Title,
        private AuthenticationService: AuthenticationService,
        private ResourceService: ResourceService,
        public Router: Router
    ) {}

    ngOnInit(): void {
        this.config = this.ConfigService.getConfig();
        this.ConfigService.getDarkMode().subscribe((next) => {
            const body = document.getElementsByTagName('body')[0];
            if (next) {
                body.classList.add('theme--dark');
            } else body.classList.remove('theme--dark');
        });

        // listen to router events
        this.Router.events.subscribe((event) => {
            if (this.fetchedInitialRouteData) {
                return;
            }
            if (event instanceof RoutesRecognized) {
                this.fetchedInitialRouteData = true;
                let route = event.state.root.firstChild;
                if (!route || !route.data.isPublic) {
                    this.AuthenticationService.fetchUser().subscribe((next) => {
                        if (!next.appCode && !route?.data?.appCode) {
                            this.AuthenticationService.onUnauth();
                        }
                        this.ConfigService.initApp(
                            next.appCode || route?.data?.appCode || this.ConfigService.getConfig().defaultAppCode
                        );
                        this.initPrivateRoute();
                    });
                } else {
                    this.TitleService.setTitle(this.config.appName);
                    this.isPublic = true;
                    this.setReady(true);
                }
                setTimeout(() => {
                    if (
                        this.ConfigService.getConfig().appCode &&
                        route?.data?.appCode &&
                        route.data.appCode != this.ConfigService.getConfig().appCode
                    ) {
                        this.Router.navigateByUrl(`/${this.ConfigService.getConfig().homeRoute}`);
                    }
                }, 1);
            }
        });
    }

    public initPrivateRoute() {
        const observables$: Observable<any>[] = [];
        observables$.push(...this.ResourceService.init());
        observables$.push(this.AuthenticationService.fetchUser());

        forkJoin(observables$).subscribe((next) => {
            this.setReady(true);
        });
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        let root = document.documentElement;
        root.style.setProperty('--editor-primary-color', '#4E85D8');
        this.ready$.next(ready);
    }
}
