import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { ConfigService } from 'src/app/services/config.service';
import { ResourceService } from 'src/app/services/resource.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent implements OnInit {
    ready: boolean = false;
    helper: any = {};
    charts: any = {};
    newsletterTypes: any[] = [];
    newsletterType: any = undefined;

    figures: any = {};
    subscriptionChartData: any = {};
    newsletters: any[] = [];

    lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: ''
                },
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 999,
                    maxRotation: 0
                }
            },
            y: {
                title: {
                    display: false,
                    text: ''
                },
                min: 0,
                suggestedMax: 10,
                ticks: {
                    stepSize: 1,
                    beginAtZero: false,
                    min: 0,
                    suggestedMax: 10,
                    maxRotation: 0,
                    callback: function (value, index, values) {
                        return value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                align: 'start',
                position: 'bottom',
                usePointStyle: false,
                labels: {
                    boxWidth: 12,
                    boxHeight: 12
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        return tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].title;
                    },
                    label: function (tooltipItem, data) {
                        var value = tooltipItem.dataset.data[tooltipItem.dataIndex].y;
                        return `${tooltipItem.dataset.label}: ${value.toString().replace('.', ',')}`;
                    }
                }
            }
        }
    };

    constructor(
        public ConfigService: ConfigService,
        private Router: Router,
        private DefaultService: DefaultService,
        private ResourceService: ResourceService
    ) {}

    ngOnInit(): void {
        this.newsletterTypes = this.ResourceService.getNewsletterTypes().map((item) => {
            return { title: item.name, value: item.id };
        });

        const observables$: Observable<any>[] = [];

        const figures$ = this.DefaultService.newslettersGetLast30DaysFigures().pipe(
            tap((next: any) => {
                this.figures = next;
                this.helper.figuresReady = true;
            })
        );
        observables$.push(figures$);

        const subscribers$ = this.DefaultService.newslettersGetSubscribersFigures().pipe(
            tap((next: any) => {
                this.subscriptionChartData = next;
                this.initSubscriptionChart();
                this.helper.subscribersReady = true;
            })
        );
        observables$.push(subscribers$);

        const newsletters$ = this.DefaultService.newslettersGetLatestNewsletterFigure(12, 0).pipe(
            tap((next: any) => {
                this.newsletters = next.data;
                if (this.newsletters?.length) {
                    this.helper.newsletterIndex = 0;
                }
                this.helper.newslettersReady = true;
            })
        );
        observables$.push(newsletters$);

        forkJoin(observables$).subscribe((next) => {
            this.ready = true;
        });
    }

    changeNewsletterType(id) {
        this.helper.chartLoading = true;
        this.newsletterType = id;
        this.DefaultService.newslettersGetSubscribersFigures(this.newsletterType).subscribe((next) => {
            this.subscriptionChartData = next;
            this.initSubscriptionChart();
        });
    }

    goToNew() {
        this.Router.navigate(['nieuwsbrieven'], { queryParams: { newsletter: 'new' } });
    }

    initSubscriptionChart() {
        const unsubscribeData = [];

        const subscribeData = [];

        var labels = [];

        // let currentDay = moment(moment().format('YYYY-MM-DD')).subtract(12, 'M').startOf('M');

        let monthsAgo = 12;

        while (monthsAgo >= 0) {
            // let dayDiff = today.diff(currentDay, 'd');

            // labels
            let today = moment(moment().format('YYYY-MM-DD')).startOf('M');
            const label = today.subtract(monthsAgo, 'M').format("MMM 'YY");
            labels.push(label);

            // data
            const subscribe = {
                x: 12 - monthsAgo,
                y:
                    this.subscriptionChartData.find((item) => {
                        return item.monthsAgo === monthsAgo;
                    })?.subscribed || 0,
                title: label
            };
            subscribeData.push(subscribe);
            const unsubscribe = {
                x: 12 - monthsAgo,
                y:
                    this.subscriptionChartData.find((item) => {
                        return item.monthsAgo === monthsAgo;
                    })?.unsubscribed || 0,
                title: label
            };
            unsubscribeData.push(unsubscribe);

            monthsAgo--;
        }

        this.charts.enrollment = {
            datasets: [
                {
                    data: unsubscribeData,
                    label: 'Uitschrijvingen',
                    fill: true,
                    borderWidth: 2,
                    borderColor: '#E54852',
                    backgroundColor: 'rgba(255, 10, 13, 0.1)',
                    pointBackgroundColor: 'transparent',
                    pointBorderWidth: 0,
                    pointBorderColor: 'transparent'
                },
                {
                    data: subscribeData,
                    label: 'Nieuwe inschrijvingen',
                    fill: true,
                    borderWidth: 2,
                    borderColor: '#50C5B7',
                    backgroundColor: 'rgba(80, 197, 183, 0.1)',
                    pointBackgroundColor: 'transparent',
                    pointBorderWidth: 0,
                    pointBorderColor: 'transparent'
                }
            ],
            labels: labels,
            options: this.lineChartOptions
        };
        this.helper.chartLoading = false;
    }
}
