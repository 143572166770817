import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EditorService } from '../../../../services/editor.service';
import Quill from 'quill';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorCropComponent } from '../../modals/editor-crop/editor-crop.component';
import { EditorImageLinkComponent } from '../../modals/editor-image-link/editor-image-link.component';

@Component({
    selector: 'app-editor-block-image',
    templateUrl: './editor-block-image.component.html',
    styleUrls: ['./editor-block-image.component.scss'],
    standalone: false
})
export class EditorBlockImageComponent implements OnInit, OnChanges {
    @Input() model: any;
    @Input() url: string;
    @Input() fileId: string;
    @Input() active: boolean = false;
    @Input() uploadQueue: { [key: string]: File } = {};
    @Input() files: any[];
    @Output() modelChange = new EventEmitter();
    @Output() urlChange = new EventEmitter();
    @Output() setActive = new EventEmitter();
    @Output() setInactive = new EventEmitter();
    @Output() setActiveModal = new EventEmitter();
    @Output() addFile = new EventEmitter();
    @Output() removeFile = new EventEmitter();

    modules = {};
    editor: Quill = null;
    file: File = null;

    constructor(
        private EditorService: EditorService,
        public sanitizer: DomSanitizer,
        private ModalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.modules = this.EditorService.getQuillModules();
    }

    openLinkModal() {
        this.EditorService.openLinkModal(this.editor, this.setActiveModal);
    }

    openAddImageModal(file: File) {
        this.setActiveModal.emit(true);
        const modalRef = this.ModalService.open(EditorImageLinkComponent, {
            windowClass: 'popup-modal editor-crop-modal'
        });
        modalRef.componentInstance.file = this.getFile();
        modalRef.componentInstance.draftFile = this.getDraftFile();
        modalRef.componentInstance.url = this.url;
        modalRef.componentInstance.aspectRatio = 602 / 275;
        modalRef.componentInstance.resizeToWidth = 1204;
        modalRef.result
            .then(() => {})
            .finally(() => {
                setTimeout(() => {
                    this.setActiveModal.emit(false);
                }, 10);
            });
        // on submit
        modalRef.componentInstance.confirmed.subscribe((FORM: { url: string; file?: File }) => {
            if (FORM.file) {
                FORM.file['sectionType'] = 'image';
                this.removeFile.emit(this.fileId);
                this.addFile.emit(FORM.file);
            }
            this.urlChange.emit(FORM.url);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.active && changes.active.currentValue) {
            this.focusEditor();
        }
    }

    onChange($event) {
        this.modelChange.emit($event.html || '');
    }

    hasFile() {
        return this.fileId && (this.getFile() || this.getDraftFile());
    }

    getFile() {
        const index = this.files
            .map((item) => {
                return item.uuid;
            })
            .indexOf(this.fileId);
        if (index != -1) {
            return this.files[index];
        } else return null;
    }

    getDraftFile() {
        return this.uploadQueue[this.fileId];
    }

    editorCreated($event) {
        this.editor = $event;
        this.focusEditor();
    }

    focusEditor() {
        setTimeout(() => {
            this.active && this?.editor.focus();
        }, 1);
    }

    unfocusEditor() {
        setTimeout(() => {
            this?.editor.root.blur();
        }, 10);
    }

    emitActive() {
        if (this.active) return;
        this.setActive.emit();
    }

    emitInactive() {
        if (!this.active) return;
        this.active && this.setInactive.emit();
    }
}
