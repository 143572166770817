import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { ContactDetailModalComponent } from 'src/app/components/modals/contact-detail-modal/contact-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { ContactService } from 'src/app/services/contact.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};
    exportBusy: boolean = false;

    // Table
    tableHeads: any = [
        { name: 'Afzender', code: 'name', sortable: true, width: '20%' },
        { name: 'Email', code: 'email', sortable: true, width: '25%' },
        { name: 'Boodschap', code: 'message', sortable: true, width: '40%' },
        { name: 'Datum', code: 'createdTS', sortable: true, width: '15%' },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'createdTS', dir: 'desc' };

    contacts: any[] = null;
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    tableLoading: boolean = false;
    ready: boolean = false;

    constructor(
        private ContactService: ContactService,
        private HelpersService: HelpersService,
        private SearchService: SearchService,
        private DefaultService: DefaultService,
        public ConfigService: ConfigService,
        private ModalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        const contactId = this.HelpersService.getParam('ContactID');
        if (contactId) this.openCreateUpdateModal(contactId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getContacts();
    }

    getContacts() {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.contactsGetContacts(
                searchSTR.startRow,
                searchSTR.rRP,
                searchSTR.term,
                searchSTR.orderBy
            )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.contacts = next.data.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    this.totalRows = next.rows;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
        });
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getContacts();
    };

    getFormattedItem(item) {
        const actions = [
            {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            }
        ];
        let nameClassName = 'text-color';

        //edit TS to format DD-MM-YYYY om HH:mm
        let formattedEditTS = formatDate(item.createdTS, 'dd-MM-yyyy T HH:mm', 'en-US');
        let omEditTS = formattedEditTS.replace('T', 'om');

        return {
            ...item,
            name: {
                type: 'default',
                classList: nameClassName,
                value: item.name
            },
            actions: {
                type: 'actions',
                actions: actions
            },
            createdTS: {
                type: 'default',
                value: omEditTS
            },
            message: {
                type: 'default',
                value: item.message,
                ellipsis: true
            }
        };
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getContacts();
    }

    deSelectRows() {
        if (this.contacts) {
            this.contacts = this.contacts.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    getDefaultSearch() {
        return {
            contact: []
        };
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(ContactDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('contactID', id);
            //add pathparam to url
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        }
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getContacts();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('contactID');
            });
        // on update
        modalRef.componentInstance.updated.subscribe((tip: any) => {
            this.getContacts();
        });
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    setSort(code: string, dir: string) {
        this.tableLoading = true;
        this.tableSort = { code: code, dir: dir };
        this.getContacts();
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    selectRow(item: any) {
        this.contacts = this.contacts.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteContact(item.id);
                break;
        }
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getContacts();
    }

    deleteContact(id: number) {
        this.dismissPopover();
        this.deSelectRows();
        this.ContactService.deleteContact(id).subscribe((next) => {
            this.getContacts();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
