import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Quill from 'quill';
import { Delta } from 'quill/core';
import Clipboard from 'quill/modules/clipboard';
import { EditorLinkComponent } from '../components/editor/modals/editor-link/editor-link.component';
import Embed from 'quill/blots/embed';

@Injectable({
    providedIn: 'root'
})
export class EditorService {
    blockTypes: any[] = [
        { code: 'text', name: 'Tekst', placeholder: 'Schrijf hier je tekst...' },
        { code: 'text-right', name: 'Afb + tekst' },
        { code: 'text-left', name: 'Tekst + afb' },
        { code: 'image', name: 'Afbeelding' },
        { code: 'video', name: 'Video' }
    ];

    initialized: boolean = false;

    constructor(private ModalService: NgbModal) {}

    init() {
        if (this.initialized) return;
        this.initialized = true;

        this.initQuill();
    }

    initQuill() {
        // links
        let Link: any = Quill.import('formats/link');

        var builtInFunc = Link.sanitize;
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
            var val = linkValueInput;

            // do nothing, since this implies user's already using a custom protocol
            if (/^\w+:/.test(val)) {
            } else if (!/^(?:f|ht)tps?\:\/\//.test(val)) val = 'http://' + val;

            return builtInFunc.call(this, val); // retain the built-in logic
        };

        // button link
        class ButtonLink extends Link {
            static create(value) {
                let node = super.create(value);
                node.setAttribute('href', value);
                return node;
            }

            static formats(domNode) {
                return domNode.getAttribute('href');
            }
        }

        ButtonLink.blotName = 'button_link';
        ButtonLink.className = 'format-button-link';
        ButtonLink.tagName = 'A';

        Quill.register({
            'formats/button_link': ButtonLink
        });

        // normal link
        class normalLink extends Link {
            static create(value) {
                let node = super.create(value);
                node.setAttribute('href', value);
                return node;
            }

            static formats(domNode) {
                return domNode.getAttribute('href');
            }
        }

        normalLink.blotName = 'normal_link';
        normalLink.className = 'format-normal-link';
        normalLink.tagName = 'A';

        Quill.register({
            'formats/normal_link': normalLink
        });

        // email link
        class emailLink extends Link {
            static create(value) {
                let node = super.create(value);
                node.setAttribute('href', value);
                return node;
            }

            static formats(domNode) {
                return domNode.getAttribute('href');
            }
        }

        emailLink.blotName = 'email_link';
        emailLink.className = 'format-email-link';
        emailLink.tagName = 'A';

        Quill.register({
            'formats/email_link': emailLink
        });

        // // clipboard
        // const Clipboard = Quill.import('modules/clipboard');
        // const Delta = Quill.import('delta');

        class PlainClipboard extends Clipboard {
            // onPaste(e) {
            //     console.log(e);
            //     // e.preventDefault();
            //     const range = this.quill.getSelection();
            //     const text = e.clipboardData.getData('text/plain');
            //     const delta = new Delta().retain(range.index).delete(range.length).insert(text);
            //     const index = text.length + range.index;
            //     let length = 0;

            //     this.quill.updateContents(delta, 'api');
            //     setTimeout(() => {
            //         this.quill.setSelection(index, length);
            //         this.quill.scrollIntoView();
            //     }, 1);
            // },
            onCapturePaste(e: ClipboardEvent): void {
                e.preventDefault();
                const range = this.quill.getSelection();
                const text = e.clipboardData.getData('text/plain');
                const delta = new Delta().retain(range.index).delete(range.length).insert(text);
                const index = text.length + range.index;
                let length = 0;

                this.quill.updateContents(delta, 'api');
                setTimeout(() => {
                    this.quill.setSelection(index, length);
                }, 1);
            }
        }

        Quill.register('modules/clipboard', PlainClipboard, true);

        // // line breaks
        // // const Delta = Quill.import("delta");
        // const Break: any = Quill.import('blots/break');

        // // const lineBreakMatcher = () => {
        // // let newDelta = new Delta();
        // // newDelta.insert({ break: "" });
        // // return newDelta;
        // // };

        // class SmartBreak extends Break {
        //     length() {
        //         // return 1;
        //         return 1;
        //     }
        //     value() {
        //         return '\n';
        //         // return '<br>';
        //     }

        //     insertInto(parent, ref) {
        //         Embed.prototype.insertAt.call(this, parent, ref);
        //     }
        // }

        // SmartBreak.blotName = 'smartbreak';
        // SmartBreak.tagName = 'br';
        // Quill.register(SmartBreak);
    }

    getQuillModules() {
        const Delta = Quill.import('delta');
        // const lineBreakMatcher = () => {
        //     let newDelta = new Delta();
        //     newDelta.insert({ smartbreak: '' });
        //     return newDelta;
        // };

        return {
            clipboard: {
                matchVisual: false
                // matchers: [['br', lineBreakMatcher]]
            },
            keyboard: {
                bindings: {
                    linebreak: {
                        key: 13,
                        shiftKey: true,
                        handler: function (range) {
                            const currentLeaf = this.quill.getLeaf(range.index)[0];
                            const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                            this.quill.insertEmbed(range.index, 'smartbreak', true, 'user');
                            // Insert a second break if:
                            // At the end of the editor, OR next leaf has a different parent (<p>)
                            if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                                this.quill.insertEmbed(range.index, 'smartbreak', true, 'user');
                            }
                            // Now that we've inserted a line break, move the cursor forward
                            this.quill.setSelection(range.index + 1, 'user');
                        }
                    }
                }
            }
        };
    }

    openLinkModal(editor: Quill, setActiveModal?: any, linkTypes?: string[]) {
        setActiveModal && setActiveModal.emit(true);
        const range = editor.getSelection(true);
        const modalRef = this.ModalService.open(EditorLinkComponent, {
            windowClass: 'popup-modal editor-link-modal'
        });
        if (range?.length) {
            modalRef.componentInstance.formSTR = {
                ...modalRef.componentInstance.formSTR,
                text: editor.getText(range.index, range.length)
            };
        }
        modalRef.componentInstance.linkTypes = linkTypes;
        modalRef.result
            .then(() => {})
            .finally(() => {
                setTimeout(() => {
                    setActiveModal && setActiveModal.emit(false);
                }, 10);
            });
        // on submit
        modalRef.componentInstance.confirmed.subscribe(({ type, text, url }) => {
            if (range?.length) {
                editor.deleteText(range.index, range.length);
            }
            editor.insertText(range.index, text, type, url);
            setTimeout(() => {
                editor.setSelection(range.index + text.length, 0);
                // editor.insertText(range.index + text.length + 1, ' test');
            }, 1);
        });
    }

    getBlockTypes(types: string[]) {
        return this.blockTypes.filter((item) => {
            return types.indexOf(item.code) != -1;
        });
    }

    getBlockType(code: string) {
        const index = this.blockTypes
            .map((item) => {
                return item.code;
            })
            .indexOf(code);
        return index != -1 && this.blockTypes[index];
    }

    addBlock(content: any[], block: any, index?: number) {
        if (index > content.length) {
            content.push(block);
        } else {
            content.splice(index, 0, block);
        }
    }

    blockIsEmpty(block) {
        if (!block) {
            return true;
        }
        switch (block.type) {
            case 'text':
                return !block.html;
            case 'text-right':
                return false;
            case 'text-left':
                return false;
            case 'image':
                return !block.image;
            case 'video':
                return !block.video;
        }
    }
}
