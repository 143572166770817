<div class="editor-block-text editor-block-text-right d-flex" [class.active]="active" [class.inactive]="!active" (click)="emitActive()">

    <div class="" style="padding-right: 26px;" (click)="unfocusEditor()">
        <div class="position-relative d-inline-block">
            <!-- placeholder -->
                <div class="editor-image-placeholder" *ngIf="!hasFile()" style="border-radius: 3px;border-top-right-radius: 0;border-bottom-right-radius: 0;" (click)="openAddImageModal()">
                <img src="/assets/img/editor/block-image-placeholder.svg" style="width: 275px; height: auto;" draggable="false">
                <span>Voeg afbeelding toe</span>
            </div>
            <!-- preview -->
            <img class="editor-image-preview" style="width: 275px; height: auto; float: left; border-radius: 3px;border-top-right-radius: 0;border-bottom-right-radius: 0;"
                draggable="false"
                *ngIf="fileId && getDraftFile()" 
                [ngfSrc]="getDraftFile()">
            <!-- blob urls -->
            <img class="editor-image-preview" style="width: 275px; height: auto; float: left; border-radius: 3px;border-top-right-radius: 0;border-bottom-right-radius: 0;"
                draggable="false"
                *ngIf="fileId && getFile()"
                [src]="getFile().blobUrl">
            
            <div *ngIf="hasFile() && active" class="editor-image-overlay d-flex align-items-center justify-content-center">
                <span (click)="openAddImageModal()"><i class="icon icon-image-edit"></i></span>
                <span (click)="removeFile.emit(fileId); urlChange.emit('')"><i class="icon icon-image-delete"></i></span>
            </div>
            <a *ngIf="hasFile() && url && active" class="editor-image-overlay--url" [href]="url">{{url}}</a>
        </div>
    </div>

    <div class="" style="padding-top: 2px;">

        <!-- edit -->
        <div class="display-hide" [class.d-block]="active">
            <quill-editor 
                (onEditorCreated)="editorCreated($event)"
                customToolbarPosition="bottom"
                placeholder="Schrijf hier je tekst..."
                [modules]="modules"
                [(ngModel)]="model"
                (onContentChanged)="onChange($event)"
                >
                <div quill-editor-toolbar>
                    <span class="ql-formats">
                        <button class="ql-header" value="1"></button>
                        <button class="ql-header" value="2"></button>
                        <button class="ql-bold" [title]="''"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <select class="ql-align" [title]="'Aligment'">
                            <option selected></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                        </select>
                        <button (click)="openLinkModal()" id="link-button"><i class="im-icon im-icon-link"></i></button>
                        <!-- <button class="ql-link"></button> -->
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                        <button class="ql-script" value="sub"></button>
                        <button class="ql-script" value="super"></button>
                        <button class="ql-clean"></button>
                    </span>
                </div>
            </quill-editor>
        </div>

        <!-- view -->
        <ng-container *ngIf="!active">
            <div style="pointer-events: none;" [innerHtml]="sanitizer.bypassSecurityTrustHtml(model)" class="ql-output"></div>
            <div style="pointer-events: none;" *ngIf="!active && !model" class="editor-emptystate">Schrijf hier je tekst...</div>
        </ng-container>
    </div>

</div>