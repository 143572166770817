import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
    standalone: false
})
export class ImageCropperComponent implements OnInit {
    @Input() file: File = null;
    @Input() aspectRatio: number = 367 / 193;
    @Input() resizeToHeight: number = 193;
    @Input() resizeToWidth: number = 367;
    @Output() added = new EventEmitter();
    croppedBase64: any = null;
    ready: boolean = false;
    loading: boolean = false;

    constructor(public ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {
        if (this.hasFile()) {
            this.ready = true;
        }
    }

    imageCropped($event: ImageCroppedEvent) {
        this.croppedBase64 = $event.base64;
    }

    cropperReady() {
        setTimeout(() => {
            this.ready = true;
        }, 300);
    }

    canSubmit() {
        return this.hasFile() || this.croppedBase64;
    }

    hasFile() {
        return this.file;
    }

    submit() {
        if (!this.croppedBase64) {
            this.added.emit(this.file);
            this.ActiveModal.dismiss();
            return;
        } else {
            const file = new File([base64ToFile(this.croppedBase64)], 'cropped.png', { type: 'image/png' });
            this.added.emit(file);
            this.ActiveModal.dismiss();
        }
    }
}
