import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss'],
    standalone: false
})
export class SideNavigationComponent implements OnInit {
    @Input() pageARR: { code: any; name: any; notify?: boolean; showSubscriptionAmount?: boolean }[];
    @Input() model?: string;
    @Input() subscriptionAmountSpinner: boolean = false;
    @Input() subscriptionAmount: number;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
